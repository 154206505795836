import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiEndpoints } from "../../Baseurl";

export const getdepartment = createAsyncThunk(
  "getdepartment",
  async (token) => {
    try {
      const { data } = await axios.get(apiEndpoints.GET_DEPARTMENT, {
        headers: { Authorization: `Bearer ${token.data.access}` },
      });

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);
