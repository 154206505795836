import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { sideActions } from "../../redux/slice/sidebarSlice";
import logo from "../../assets/img/logo.png";
import dashboard1 from "../../assets/img/dashbord1.png";
import employee from "../../assets/img/employee.png";
import manager from "../../assets/img/manager.png";
import users from "../../assets/img/users.png";
import setings from "../../assets/img/settings.png";
import support from "../../assets/img/support.png";
import logout from "../../assets/img/logout.png";
import { facilitystateblank } from "../../redux/action/facility";
import { filterActions } from "../../redux/slice/filterslice";

const DashAside = () => {
  const [cookie, setcookie, removeCookie] = useCookies(["token"]);
  const dispatch = useDispatch();
  const userlogout = () => {
    dispatch(facilitystateblank());
    removeCookie("token");
  };

  var currentDate = new Date();

var lastSevenDayDate = new Date();
lastSevenDayDate.setDate(currentDate.getDate() - 7);

var formattedDate = lastSevenDayDate.toISOString().split("T")[0];

var currentDates = new Date();

var year = currentDates.getFullYear();
var month = currentDates.getMonth() + 1;
var day = currentDates.getDate();

var formattedDate1 = year + "-" + month + "-" + day;


  const location = useLocation();
  const currentPath = location.pathname;

  const isActiveTab = (tabName) => {
    return currentPath.includes(tabName);
  };

  return (
    <div
      className="dash-sidebar px-3"
      style={{ borderRadius: "20px", marginTop: "0.5%", marginBottom: "0.5%" }}
    >
      <div className="sidebar-inner">
        <div className="side-logo text-center">
          <img src={logo} alt="logo" className="img-fluid logo-side" />
        </div>
        <AiFillCloseCircle
          style={{ cursor: "pointer" }}
          className="hamburger-close cursor-pointer"
          onClick={() => {
            dispatch(sideActions.toggleSlideBar());
          }}
        />
        <div className="divider my-2"></div>
        <div className="d-flex flex-column justify-content-between dash-side-main">
          <div className="side-tabs">
            <NavLink
              exact
              to="/dashboard"
              className="dash-tab"
              activeClassName="active"
              onClick={(e)=>{
                dispatch(
                filterActions.changedate({
                  startDate: formattedDate,
                  endDate: formattedDate1,
                }))

                dispatch(filterActions.changesearch(""));

                dispatch(filterActions.changeposition(""));
    
                //     dispatch(
                //       filterActions.changedate({
                //         startDate: startDateFormatted,
                //         endDate: endDateFormatted,
                //       }))
      

              }}
            >
              <div
                className={`tab my-3 ${
                  isActiveTab("dashboard") ? "active" : ""
                }`}
              >
                <div className="">
                  <img src={dashboard1} alt="" className="vector-img" />
                  <span className="white ms-4">Dashboard</span>
                </div>
              </div>
            </NavLink>
            <NavLink
              to="/employees"
              className="dash-tab"
              activeClassName="active"
            >
              <div
                className={`tab my-3 ${
                  isActiveTab("employees") ? "active" : ""
                }`}
              >
                <div className="">
                  <img src={employee} alt="" className="vector-img" />
                  <span className="white ms-4">Employees</span>
                </div>
              </div>
            </NavLink>
            <NavLink
              to="/managers"
              className="dash-tab"
              activeClassName="active"
            >
              <div
                className={`tab my-3 ${
                  isActiveTab("managers") ? "active" : ""
                }`}
              >
                <div className="">
                  <img src={manager} alt="" className="vector-img" />
                  <span className="white ms-4">Managers</span>
                </div>
              </div>
            </NavLink>
            {/* <NavLink
              to="/user-management"
              className="dash-tab"
              activeClassName="active"
            >
              <div
                className={`tab my-3 ${
                  isActiveTab("user-management") ? "active" : ""
                }`}
              >
                <div className="">
                  <img src={users} alt="" className="vector-img" />
                  <span className="white ms-4">User Management</span>
                </div>
              </div>
            </NavLink>
            <NavLink
              to="/settings"
              className="dash-tab"
              activeClassName="active"
            >
              <div
                className={`tab my-3 ${
                  isActiveTab("settings") ? "active" : ""
                }`}
              >
                <div className="">
                  <img src={setings} alt="" className="vector-img" />
                  <span className="white ms-4">Settings</span>
                </div>
              </div>
            </NavLink>
            <NavLink
              to="/support"
              className="dash-tab"
              activeClassName="active"
            >
              <div
                className={`tab my-3 ${isActiveTab("support") ? "active" : ""}`}
              >
                <div className="">
                  <img src={support} alt="" className="vector-img" />
                  <span className="white ms-4">Support</span>
                </div>
              </div>
            </NavLink> */}
            <NavLink className="dash-tab" onClick={userlogout}>
              <div className="tab">
                <div className="">
                  <img src={logout} alt="" className="vector-img" />
                  <span className="white ms-4">Logout</span>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashAside;
