import React, { useEffect, useState } from "react";
import total1 from "../../assets/img/total-top.png";
import total from "../../assets/img/total.png";
import DonutChart from "react-donut-chart";

const DashTotalCallOffs = ({ headercallofdata }) => {
  var data = headercallofdata?.positions;

  const [datas, setdatas] = useState([]);
  useEffect(() => {
    if (data) {
      let chart = [];
      const shuffledColors = shuffleArray(reactDonutChartBackgroundColor);

      for (const key in data) {
        chart.push({
          label: key,
          value: data[key],
          color: shuffledColors.pop(), // Pop a color from the shuffled array
        });
      }
      setdatas(chart);
    }
  }, [data]);

  // Function to shuffle an array
  const shuffleArray = (arr) => {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };

  const reactDonutChartBackgroundColor = [
    "#4665D3",
    "#BB88FD",
    "#FDCC00",
    "#EA7979",
    "#A0CF30",
    "#FFFFFF",
  ];
  const reactDonutChartInnerRadius = 0.7;
  const reactDonutChartSelectedOffset = 0;
  const reactDonutChartHandleClick = (item, toggled) => {
    if (toggled) {
    }
  };
  let reactDonutChartStrokeColor = "#FFFFFF";
  const reactDonutChartOnMouseEnter = (item) => {
    let color = datas.find((q) => q.label === item.label).color;
    reactDonutChartStrokeColor = color;
  };

  return (
    <div className="container-fluid total-call-wrapper">
      <div className="row">
        <div className="col-lg-4  pe-lg-3 pe-0 ps-0 ">
          <div className="border-2 radius-10 ps-4 pt-4">
            <div className="total-top pb-5">
              <img src={total1} alt="img" />
            </div>
            <div className="total">
              <img src={total} alt="img" />
              <span className="call-off-number mx-2">
                {headercallofdata?.total_call_offs}
              </span>
              <span className="call-off%">
                {" "}
                {headercallofdata?.percentage_change}%
              </span>
            </div>
            <div className="cl-time">
              <p>Call-Offs - Last 7 days</p>
            </div>
          </div>
        </div>
        <div className="col-lg-8 border-2 radius-10 py-2 dought-height mt-3 mt-lg-0">
          <div className="row " style={{ height: "100%" }}>
            <div
              className="doughtnut col-xxl-2 col-lg-3 col-md-2 col-sm-2 col-2"
              style={{ position: "relative" }}
            >
              <div className="" style={{ position: "absolute" }}>
                <DonutChart
                  width={240}
                  onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                  strokeColor={reactDonutChartStrokeColor}
                  data={datas}
                  colors={datas.map((item) => item.color)}
                  innerRadius={reactDonutChartInnerRadius}
                  selectedOffset={reactDonutChartSelectedOffset}
                  onClick={(item, toggled) =>
                    reactDonutChartHandleClick(item, toggled)
                  }
                />
              </div>
              <div
                className=" d-flex flex-column text-center doughnut-center "
                // style={{ position: "absolute", left: "23%", top: "55%" }}
              >
                <span style={{ fontSize: "30px" }}>
                  {headercallofdata?.total_call_offs}
                </span>
                <p className="call-off-text">Call-Offs</p>
              </div>
            </div>
            <div className="doughnut-detail col-xxl-10 col-lg-9 col-md-9 col-sm-8  col-8 ms-5 ms-lg-0 ">
              <div className="mt-4 container-fluid ms-5">
                <h4 className="call-postion-text">
                  Total call-offs by position this week
                </h4>
                <div className="itemsss row">
                  {datas.map((item, index) => (
                    <div className="col-xxl-4 col-md-6 ">
                      <span className="mx-xl-3 mx-0">
                        <img
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: item.color,
                          }}
                        />
                        <span className="ms-2">
                          <b style={{ fontSize: "14px" }}>
                            {item.value} -{item.label}
                          </b>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashTotalCallOffs;
