import "./App.css";
import "./css/responsive.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import DashMain from "./component/dashboard/DashMain";
import Employee from "./component/dashPages/Employee";
import Managers from "./component/dashPages/Managers";
import Settings from "./component/dashPages/Settings";
import UserManagement from "./component/dashPages/UserManagement";
import Support from "./component/dashPages/Support";
import { Cookies } from "react-cookie";
import { fetchFacility } from "./redux/action/facility";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { filterActions } from "./redux/slice/filterslice";
import ForgotPassword_send_mail from "./pages/ForgotPassword_send_mail";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const { data } = useSelector((state) => state.Facility);
  const { facility_Id } = useSelector(
    (state) => state.filter
  );
  const [progress, setProgress] = useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token && data === null) {
      dispatch(fetchFacility(token));
    }
  }, [token]);

  useEffect(() => {
    if (data?.facilities.length) {
      let id;
      let sessionId = JSON.parse(sessionStorage.getItem("facility"));

      if (facility_Id == "") {
        id = sessionId
          ? sessionId.id
          : facility_Id
            ? facility_Id
            : data?.facilities[0]?.id;
        dispatch(
          filterActions.changeId(
            sessionId ? sessionId.id : data?.facilities[0]?.id
          )
        );
      } else {
        id = facility_Id;
      }
    }
  }, [data]);
  return (
    <div>
      <BrowserRouter>
        <LoadingBar
          height={5}
          color="#8640df"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<DashMain setProgress={setProgress} />}
          />
          <Route
            path="/employees"
            element={<Employee setProgress={setProgress} />}
          />
          <Route
            path="/managers"
            element={<Managers setProgress={setProgress} />}
          />
          <Route
            path="/settings"
            element={<Settings setProgress={setProgress} />}
          />
          <Route
            path="/support"
            element={<Support setProgress={setProgress} />}
          />
          <Route
            path="/user-management"
            element={<UserManagement setProgress={setProgress} />}
          />
          <Route
            path="/forgot-passsword-send-mail"
            element={<ForgotPassword_send_mail setProgress={setProgress} />}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword setProgress={setProgress} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
