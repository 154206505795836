import React from "react";
import imgbox from "../assets/img/ImageBox.png";
import email from "../assets/img/email.svg";
import pass from "../assets/img/pass.svg";
import lg_big from "../assets/img/logo-big.jpg";
import axios from "axios";
import { useState } from "react";
import { apiEndpoints } from "../Baseurl";
import { Cookies } from "react-cookie";
import loginloader from "../assets/img/loading.gif";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Login() {
  const cookie = new Cookies();
  const token = cookie.get("token");
  const [logindata, setLoginData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [err_email, setErr_email] = useState("");
  const [err_password, setErr_password] = useState("");
  // deepakrajpurohit945@gmail.com admin
  const [loading, setloading] = useState(false);
  const handlechange = (e) => {
    setLoginData({ ...logindata, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]);

  const login = async (e) => {
    e.preventDefault();
    if (!logindata.email.length) {
      setErr_email("Email is Required");
    }
    if (!logindata.password.length) {
      setErr_password("Password is Required");
    } else if (!logindata.email.length || !logindata.password.length) {
      return false;
    } else {
      setloading(true);
      try {
        const { data } = await axios.post(apiEndpoints.LOGIN, logindata);

        cookie.set("token", JSON.stringify({ data }), {
          expires: new Date(Date.now() + 3600000),
        });
        setloading(false);
        navigate("/dashboard");
      } catch (error) {
        setloading(false);
if(error.response?.data?.detail){

  toast.error(error.response?.data?.detail, {
    position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    }
  };
  return (
    <>
      <div className="login-page container-fluid">
        <div className="row m-0">
          <div className="col-lg-6 p-0 login-col-left">
            <div className="login-left">
              <div>
                {" "}
                <img
                  style={{ borderRadius: "20%" }}
                  src={lg_big}
                  alt="logo-wel"
                />
              </div>
              <div className="img-conent">
                <h1>
                  <span className="login-coverd">Complete control </span>and
                  <span className="login-coverd"> visualization</span> of
                  call-offs
                </h1>
                <h4>
                  Seamlessly manage notification, metrics and reporting for
                  staff call-offs.
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0 login-col-right">
            <div className="login-right">
              <h2>
                Your Shifts <span className="login-coverd">Covered</span>
              </h2>
              <form onSubmit={login} className="login-main-form">
                <div className="email-input">
                  <input
                    type="email"
                    name="email"
                    value={logindata.email}
                    onChange={(e) => {
                      setErr_email("");
                      handlechange(e);
                    }}
                    placeholder="Email Address"
                  />
                  <img src={email} alt="email" />
                </div>
                {err_email ? <p className="err">{err_email}</p> : ""}
                <div className="email-input">
                  <input
                    value={logindata.password}
                    name="password"
                    onChange={(e) => {
                      setErr_password("");
                      handlechange(e);
                    }}
                    type="password"
                    placeholder="Password"
                  />
                  <img src={pass} alt="email" />
                </div>
                {err_password ? <p className="err">{err_password}</p> : ""}

                <h5 className="login-forgot" style={{cursor:"pointer"}} onClick={()=>{
                  navigate("/forgot-passsword-send-mail")
                }}>Forgot Password</h5>
                <button className={`login-btn ${loading ? `p0` : ""}  `}>
                  {loading ? (
                    <img className="gif" src={loginloader} />
                  ) : (
                    "Login"
                  )}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
