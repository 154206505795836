import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiEndpoints } from "../../Baseurl";

export const fetchFacility = createAsyncThunk(
  "fetchFacility",
  async (token) => {
    try {
      const { data } = await axios.get(apiEndpoints.GET_FACILITY, {
        headers: { Authorization: `Bearer ${token.data.access}` },
      });

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const dashboardHeadercallof = createAsyncThunk(
  "dashboardHeadercallof",
  async ({ token, id }) => {
    try {
      const { data } = await axios.get(
        `${apiEndpoints.GET_DASHBOARD_HEADER}/${id}`,
        { headers: { Authorization: `Bearer ${token.data.access}` } }
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const dashboardbodycallof = createAsyncThunk(
  "dashboardbodycallof",
  async ({ token, id, start_date, end_date, search_name, position_name }) => {
    try {
      const { data } = await axios.post(
        `${apiEndpoints.GET_DASHBOARD_BODY}/${id}`,
        { start_date, end_date, search_name, position_name },
        { headers: { Authorization: `Bearer ${token.data.access}` } }
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const dashboardChart = createAsyncThunk(
  "dashboardChart",
  async ({ token, id, start_date, end_date, search_name }) => {
    try {
      const date = {
        start_date: start_date,
        end_date: end_date,
        search_name: search_name,
      };
      const { data } = await axios.post(
        `${apiEndpoints.GET_DASHBOARD_CHART}/${id}`,
        date,
        { headers: { Authorization: `Bearer ${token.data.access}` } }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const facilitystateblank = createAsyncThunk(
  "facilitystateblank",
  async () => {
    try {
      let data = null;

      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);
