import { createSlice } from "@reduxjs/toolkit";
import { dashboardChart, facilitystateblank } from "../action/facility";

const dashboardChartslice = createSlice({
  name: "dashboardChart",
  initialState: {
    isLoading: false,
    chart: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(dashboardChart.fulfilled, (state, action) => {
      state.chart = action.payload;
      state.isLoading = false;
    });
    builder.addCase(dashboardChart.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(dashboardChart.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });


  },
});

export default dashboardChartslice.reducer;
