import { createSlice } from "@reduxjs/toolkit";
var currentDate = new Date();

var lastSevenDayDate = new Date();
lastSevenDayDate.setDate(currentDate.getDate() - 7);

var formattedDate = lastSevenDayDate.toISOString().split("T")[0];

var currentDates = new Date();

var year = currentDates.getFullYear();
var month = currentDates.getMonth() + 1;
var day = currentDates.getDate();

var formattedDate1 = year + "-" + month + "-" + day;

const filterdata = createSlice({
  name: "filter",
  initialState: {
    facility_Id: "",
    progress: 0,
    date: { start: formattedDate, end: formattedDate1 },
    position: "",
    search: "",
  },
  reducers: {
    changeId: (state, action) => {
      state.facility_Id = action.payload;
    },
    changedate: (state, action) => {
      state.date.start = action.payload.startDate;
      state.date.end = action.payload.endDate;
    },
    changeposition: (state, action) => {
      state.position = action.payload;
    },
    changesearch: (state, action) => {
      state.search = action.payload;
    },
    changeporgress: (state, action) => {
      state.progress = action.payload;
    },
  },
});

export const filterActions = filterdata.actions;

const filterReducer = filterdata.reducer;
export default filterReducer;
