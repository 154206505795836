import React from "react";
import { useCookies } from "react-cookie";
import DashDropdown from "./DashDropdown";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { sideActions } from "../../redux/slice/sidebarSlice";

const DashboardHeader = () => {
  const [cookies] = useCookies(["token"]);
  var userData = cookies.token;
  var firstName = "User";
  var lastName = "";
  if (
    userData !== null &&
    userData !== undefined &&
    userData.data !== undefined
  ) {
    firstName = userData.data.first_name;
    lastName = userData.data.last_name;
  }
  const dispatch = useDispatch();
  // const { showSidebar } = useSelector((state) => state.hamburger);
  return (
    <div className="dash-user-wrapper d-flex justify-content-between   ">
      <div className="user-left  d-flex flex-column justify-content-center">
        <GiHamburgerMenu
          className="hamburger"
          onClick={() => {
            dispatch(sideActions.toggleSlideBar());
          }}
        />
        <h1 className="text-white welcome">Welcome, {firstName}</h1>
        <span className="text-white fs_10">Here's this weeks call off report</span>
      </div>
      <div className="user-right d-flex  align-items-center">
        <div className="user-avatar">
          <img
            src="https://www.pngmart.com/files/22/User-Avatar-Profile-PNG-Isolated-Transparent-Picture.png"
            alt="avatar"
            style={{ height: "60px" }}
          />
        </div>
        <div className="user-name">
          <h3 className="text-white u_name">
            {firstName} {lastName}
          </h3>
          <span className="text-white fs_10">Administrator</span>
        </div>
        <div className="user-dropdown ms-3">
          <DashDropdown />
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
