import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export const Modals = (props) => {
  return (
    <>
      <div className="blurFacility">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter "
          centered
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "rgb(101 105 111)" }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              Facility Alert
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "rgb(101 105 111)" }}>
            <p>
              You are not attached to any facilities. Please contact to the
              Administrator to proceed further.
            </p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
