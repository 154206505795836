import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFacility,
  dashboardHeadercallof,
  facilitystateblank,
} from "../action/facility";

const getFacility = createSlice({
  name: "facility",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    headercallofdata: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFacility.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchFacility.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFacility.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(dashboardHeadercallof.fulfilled, (state, action) => {
      state.headercallofdata = action.payload;
      state.isLoading = false;
    });
    builder.addCase(dashboardHeadercallof.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(dashboardHeadercallof.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(facilitystateblank.fulfilled, (state, action) => {
      state.data = null;
      state.headercallofdata = null;
      state.isLoading = false;
    });
    builder.addCase(facilitystateblank.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(facilitystateblank.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default getFacility.reducer;
// export default headercallof.reducer
