import { createSlice } from "@reduxjs/toolkit";
const screenWidth = window.innerWidth;
const slidebarShow = createSlice({
    name: "hamburger",
    initialState: { showSidebar: screenWidth > 1260 ? true : false, },
    reducers: {
        toggleSlideBar: (state) => {
            state.showSidebar = !state.showSidebar
        }
    },
})

export const sideActions = slidebarShow.actions

const sideReducer = slidebarShow.reducer
export default sideReducer