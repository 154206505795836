import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
import { NavLink,Link } from "react-router-dom";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const Breadcrumb = (props) => {
  return (
    <div role="presentation" onClick={handleClick} className="my-4">
      <Breadcrumbs aria-label="breadcrumb" className="white">
        <Link underline="hover"  color="inherit" to="/dashboard" className="white">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to={props.link}
          className="white"
        >
          <b>{props.name}</b>
        </Link>
        {/* <Typography color="text.primary" className="white">
          Breadcrumbs
        </Typography> */}
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
