import React, { useState } from "react";
import DashAside from "./DashAside";
// import DashDropdown from "./DashDropdown";
import DashboardHeader from "./DashboardHeader";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardHeadercallof,
  facilitystateblank,
  fetchFacility,
} from "../../redux/action/facility";
import { Modals } from "../layout/Modal";

import Breadcrumb from "./Breadcrumb";
import DashTotalCallOffs from "./DashTotalCallOffs";
import DashFilters from "./DashFilters";
import { Divider } from "@mui/material";
import { getemployee } from "../../redux/action/employee";
const DashMain = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const { showSidebar } = useSelector((state) => state.hamburger);
  useEffect(() => {
    props.setProgress(100);
  }, []);
  const dispatch = useDispatch();
  const { headercallofdata, isLoading, data, isError } = useSelector(
    (state) => state.Facility
  );
  const cookie = new Cookies();
  const navigate = useNavigate();
  const token = cookie.get("token");

  useEffect(() => {
    if (!token) {
      return navigate("/");
    }
  }, [token]);

  useEffect(() => {
    if (isLoading) {
      return setModalShow(false);
    }
    if (data !== null && !data?.facilities.length) {
      setModalShow(true);
    }
  }, [data]);

  return (
    <div className="dash-wrapper ">
      <div className="dashboard d-flex ">
        {showSidebar && <DashAside />}
        {data?.facilities.length ? (
          <div
            className="dash-main mx-xl-5 mx-lg-4 mx-md-3 mx-2 mt-3"
            style={{ width: "100%" }}
          >
            <DashboardHeader />
            <div className="dashboard-content ">
              <Breadcrumb name={"Dashboard"} link={"/dashboard"} />
              <DashTotalCallOffs headercallofdata={headercallofdata} />
              <Divider className="my-5" />
              <DashFilters />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {!isLoading ? (
        <Modals
          className="blurFacility"
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            cookie.remove("token");
            dispatch(facilitystateblank());
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DashMain;
