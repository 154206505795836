import React from "react";
import DashAside from "../dashboard/DashAside";
import DashboardHeader from "../dashboard/DashboardHeader";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import searchimg from "../../assets/img/search.png";
import dragImg from "../../assets/img/drag_sk.svg";
import teer from "../../assets/img/teer.svg";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import sort from "../../assets/img/Sort.png";
import { GrClose } from "react-icons/gr";
import { IoIosAddCircle } from "react-icons/io";
import Breadcrumb from "../dashboard/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import loginloader from "../../assets/img/loading.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField,
} from "@mui/material";
import {
  addmanager,
  getmanager,
  managertateblank,
} from "../../redux/action/manager";
import { getdepartment } from "../../redux/action/department";
// import InputLabel from '@mui/material/InputLabel';
import ManagerTable from "../dashboard/ManagerTable";

const Managers = (props) => {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const [fillter_position, setfillter_position] = useState([]);

  const { manager } = useSelector((state) => state);
  const { isLoading, addmanagerData } = useSelector((state) => state.manager);
  const { departmentdata } = useSelector((state) => state.department);
  const { showSidebar } = useSelector((state) => state.hamburger);
  const { bodycallofdata } = useSelector((state) => state.bodyfacilitydata);

  const cookie = new Cookies();
  const navigate = useNavigate();
  const token = cookie.get("token");
  const { data } = useSelector((state) => state.Facility);
  const { facility_Id } = useSelector((state) => state.filter);
  let sessionId = JSON.parse(sessionStorage.getItem("facility"));
  const [facility, setfacility] = useState("");
  const [position, setposition] = useState("");
  const [search, setsearch] = useState("");
  const [is_terminated, setis_terminated] = useState(true);
  useEffect(() => {
    if (data?.facilities.length) {
      let id = sessionId
        ? sessionId?.id
        : facility_Id
        ? facility_Id
        : data?.facilities[0]?.id;

      dispatch(
        getmanager({
          token,
          id,
          name: search,
          position,
          is_terminated: is_terminated ? false : true,
        })
      );
    }
  }, [data, facility_Id, search, is_terminated, position]);
  const [departmentdatas, setdepartmentdatas] = useState([]);
  useEffect(() => {
    if (manager?.manager) {
      setrows(manager?.manager);
    }
    if (departmentdata === null) {
      dispatch(getdepartment(token));
    }
  }, [manager?.manager]);

  const [options, setoptions] = useState([]);
  useEffect(() => {
    if (departmentdata) {
      let arr = [];
      departmentdata &&
        departmentdata.forEach((it) => {
          arr.push({ value: it.id, label: it.name });
          setoptions(arr);
        });
    }
  }, [departmentdata]);

  useEffect(() => {
    if (addmanagerData?.response?.status === 400) {
      let arr = [];
      let err = addmanagerData?.response?.data;
      for (const i in err) {
        let msg = i + " " + err[i];
        arr.push(msg);
      }

      toast.error(arr[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(managertateblank());
    }
    if (addmanagerData?.status === 201 && addmanagerData !== null) {
      toast.success("successfully Inserted ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(
        getmanager({
          token,
          id: facility_Id,
          name: search,
          position,
          is_terminated,
        })
      );
      dispatch(managertateblank());

      setShowAddNew(false);
    }
  }, [addmanagerData]);

  useEffect(() => {
    setfillter_position(bodycallofdata?.positions);
  }, [bodycallofdata]);
  const [startDate, setStartDate] = useState(new Date());

  const [managerdata, setmanagerdata] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    can_text: true,
    can_email: true,
    is_terminated: false,
    termination_date: startDate,
    position: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "can_text" || e.target.name === "can_email") {
      return setmanagerdata({
        ...managerdata,
        [e.target.name]: e.target.checked,
      });
    }
    setmanagerdata({ ...managerdata, [e.target.name]: e.target.value });
  };

  const [lblsearch, setlblsearch] = useState("");

  const [showAddNew, setShowAddNew] = useState(false);
  const handleAddClose = () => setShowAddNew(false);
  const handleAddShow = () => setShowAddNew(true);
  //
  // edit modal
  const [showEdit, setshowEdit] = useState(false);
  const handleEditClose = () => setshowEdit(false);
  const handleEditShow = () => setshowEdit(true);
  //
  const [sortColumn, setSortColumn] = useState("fname"); // Initial sort column
  const [sortDirection, setSortDirection] = useState("asc"); // Initial sort direction
  const [sortColumn1, setSortColumn1] = useState("lname");
  const [sortColumn2, setSortColumn2] = useState("date");

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const datatable = {
    columns: [
      {
        label: (
          <>
            {sortColumn === "fname" && (
              <div>
                {" "}
                <img src={sort} style={{ marginRight: "2px" }} />
                First Name
              </div>
            )}
          </>
        ),
        field: "first_name",
        sort: "asc",
        width: 150,
        formatter: (value) => <span style={{ color: "red" }}>{value}</span>,
      },
      {
        label: (
          <>
            {sortColumn1 === "lname" && (
              <div>
                {" "}
                <img src={sort} style={{ marginRight: "2px" }} />
                Last Name
              </div>
            )}
          </>
        ),
        field: "last_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Department",
        field: "department",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone Number",
        field: "phone",
        sort: "asc",
        width: 100,
      },
      {
        label: "Email Address",
        field: "email",
        sort: "asc",
        width: 200,
      },
    ],
    rows: rows,
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Filter Position");
  function handleBlur(e) {}
  // multiselect
  const animatedComponents = makeAnimated();

  return (
    <div className="dash-wrapper ">
      <div className="dashboard d-flex ">
        {showSidebar && <DashAside />}

        <div className="dash-main mx-5 mt-3" style={{ width: "100%" }}>
          <DashboardHeader />
          <Breadcrumb name={"Managers"} link={"/managers"} />
          <div className="employee-dash container-fluid">
            <div className="filter-emp row">
              <div className="switch-tgle  col-xl-2 col-lg-2 col-md-4 col-sm-6 ">
                {" "}
                <Switch
                  {...label}
                  checked={is_terminated ? "checked" : ""}
                  onChange={(e) => {
                    setis_terminated(e.target.checked);
                    navigate("/managers", {
                      state: { is_terminated: e.target.checked ? false : true },
                    });
                  }}
                />
                Active
              </div>
              <div className="emp-search col-xl-3 col-lg-3 col-md-4 col-sm-6 px-2">
                <div className="emp-search-in pe-2 px-2">
                  <img src={searchimg} />
                  <input
                    type="text"
                    placeholder="search here"
                    className="ms-3 placeholder"
                    value={lblsearch}
                    onChange={(e) => {
                      setlblsearch(e.target.value);
                      if (e.target.value.length >= 3) {
                        return setsearch(e.target.value);
                      }
                      if (e.target.value === "") {
                        return setsearch("");
                      }
                    }}
                  />
                </div>
              </div>

              <div
                className="clear-filter col-xl-3 col-lg-3 col-md-4 col-sm-6 mt-2 mt-lg-0"
                onClick={() => {
                  setsearch("");
                  setlblsearch("");
                  setis_terminated(true);
                  setposition("");
                  handleEditShow();
                  setIsSelected("Filter Position");
                }}
              >
                <button>Clear Filter</button>
              </div>

              <div
                className="add-employees col-xl-3 col-lg-3 col-md-4 col-sm-6 mt-2 mt-xl-0 p-0 pointer"
                onClick={handleAddShow}
              >
                <div className="py-3 py-xl-0">
                  <IoIosAddCircle fontSize={25} color="black" />
                  <span className="ms-2 "> Add New</span>
                </div>
              </div>
            </div>
            <div className="emp-data-table col-xl-12">
              <div style={{ width: "100%" }}>
                {/* {rows && <MDBDataTable striped bordered small data={datatable} />} */}
                <ManagerTable datatable={datatable} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add new modal */}
      <Modal
        show={showAddNew}
        onHide={handleAddClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="text-dark container-fluid">
            <div className="row">
              <div className="col-11">
                <h3>Add Manager</h3>
              </div>
              <div className="col-1">
                <GrClose onClick={handleAddClose} className="pointer" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <FormControl size="" className="form-control pointer">
                  <Select
                    className="dept_multi pointer"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    placeholder="Department"
                    isMulti
                    options={options}
                    onChange={(e, val) => {
                      let arr = [];
                      e.forEach((it) => {
                        arr.push(it.value);
                      });
                      setdepartmentdatas(arr);
                    }}
                  />
                </FormControl>
              </div>

              <div className="col-6">
                <FormControl size="" className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="email"
                    variant="outlined"
                    name="email"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
              {/* <div className="col-6">
                <FormControl size="" className="form-control">
                  <DatePicker
                    className=" date-emp"
                    name="termination_date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </FormControl>
              </div> */}
            </div>
            <Divider className="my-4"></Divider>
            <div className="row">
              <div className="col-6">
                <FormControl size="" className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    name="first_name"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
              <div className="col-6">
                <FormControl size="" className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    name="last_name"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
            </div>
            <div className="row mt-3"></div>

            <div className="row mt-3">
              <div className="col-12">
                <FormControl size="" className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Phone"
                    type="number"
                    variant="outlined"
                    name="phone"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>

              <div className="col-6"></div>
            </div>
            <div className="row my-4">
              <div className="col-6">
                <span className="fs10">
                  Choose an admin role from the options provided
                </span>
              </div>
              <div className="col-6">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    checked={managerdata.can_text ? "cheked" : ""}
                    // value={managerdata.can_text}
                    name="can_text"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label fs10 pointer"
                    for="inlineCheckbox1"
                  >
                    Send SMS/Text
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    checked={managerdata.can_email ? "cheked" : ""}
                    // value={managerdata.can_email}
                    onChange={handleChange}
                    name="can_email"
                  />
                  <label
                    className="pointer form-check-label fs10"
                    for="inlineCheckbox2"
                  >
                    Send email
                  </label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <button
                  onClick={() => {
                    dispatch(
                      addmanager({
                        token,
                        managerdata,
                        departmentdatas,
                        facility_Id,
                      })
                    );
                  }}
                  className={`btn-purple  text-white ${isLoading ? `p0` : ""}`}
                >
                  {isLoading ? (
                    <img className="gif" src={loginloader} />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Mod */}
      {/* add new over */}
      {/* edit modal */}

      {/*edit modal over */}
      <ToastContainer />
    </div>
  );
};

export default Managers;
