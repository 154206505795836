import { createSlice } from "@reduxjs/toolkit";
import { getdepartment } from "../action/department";

const departmentslice = createSlice({
  name: "filter",
  initialState: { isLoading: false, departmentdata: null, isError: false },
  extraReducers: (builder) => {
    builder.addCase(getdepartment.fulfilled, (state, action) => {
      state.departmentdata = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getdepartment.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getdepartment.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});
export default departmentslice.reducer;
