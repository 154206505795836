import { createSlice } from "@reduxjs/toolkit";
import {
  addmanager,
  getmanager,
  getsinglemanager,
  managertateblank,
  update_manager,
} from "../action/manager";

const managerslice = createSlice({
  name: "manager",
  initialState: {
    isLoading: false,
    manager: null,
    isError: false,
    addmanagerData: null,
    update_managerData: null,
    update_loading: false,
    update_error: null,
    singlemanagerData: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getmanager.fulfilled, (state, action) => {
      state.manager = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getmanager.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getmanager.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(addmanager.fulfilled, (state, action) => {
      state.addmanagerData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(addmanager.pending, (state, action) => {
      state.isLoading = true;
      state.addmanagerData = null;
    });
    builder.addCase(addmanager.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(update_manager.fulfilled, (state, action) => {
      state.update_managerData = action.payload;
      state.update_loading = false;
    });
    builder.addCase(update_manager.pending, (state, action) => {
      state.update_loading = true;
      state.update_managerData = null;
    });
    builder.addCase(update_manager.rejected, (state, action) => {
      state.update_error = action.payload;
      state.update_loading = false;
    });

    builder.addCase(getsinglemanager.fulfilled, (state, action) => {
      state.singlemanagerData = action.payload;
      state.update_loading = false;
    });
    builder.addCase(getsinglemanager.pending, (state, action) => {
      state.update_loading = true;
      state.singlemanagerData = null;
    });
    builder.addCase(getsinglemanager.rejected, (state, action) => {
      state.update_error = action.payload;
      state.update_loading = false;
    });

    builder.addCase(managertateblank.fulfilled, (state, action) => {
      state.addmanagerData = action.payload;
      state.update_managerData = action.payload;
      state.update_loading = false;
    });
    builder.addCase(managertateblank.pending, (state, action) => {
      state.update_loading = true;
      state.addmanagerData = null;
      state.update_managerData = action.payload;
    });
    builder.addCase(managertateblank.rejected, (state, action) => {
      state.update_error = action.payload;
      state.update_loading = false;
    });
  },
});

export default managerslice.reducer;
