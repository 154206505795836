import { createSlice } from "@reduxjs/toolkit";
import { dashboardbodycallof, facilitystateblank } from "../action/facility";

const dashboardbodycallofslice = createSlice({
  name: "dashboardbodycallof",
  initialState: {
    isLoading: false,
    bodycallofdata: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(dashboardbodycallof.fulfilled, (state, action) => {
      state.bodycallofdata = action.payload;
      state.isLoading = false;
    });
    builder.addCase(dashboardbodycallof.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(dashboardbodycallof.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(facilitystateblank.fulfilled, (state, action) => {
      state.bodycallofdata = null;
      state.isLoading = false;
    });
    builder.addCase(facilitystateblank.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(facilitystateblank.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export default dashboardbodycallofslice.reducer;
