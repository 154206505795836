import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiEndpoints } from "../../Baseurl";

export const getmanager = createAsyncThunk(
  "getmanager",
  async ({ token, id, name, position, is_terminated }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };
      const data = {
        name,
        position,
        is_terminated: is_terminated,
      };
      const response = await axios.post(
        `${apiEndpoints.GET_MANAGERS_LIST}/${id}/managers`,
        data,
        { headers }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addmanager = createAsyncThunk(
  "addmanager",
  async ({ token, id, managerdata, departmentdatas, facility_Id }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };
      //  const data = {
      //     name,position,
      //     is_terminated: is_terminated,
      // }
      const response = await axios.post(
        `${apiEndpoints.ADD_MANAGER}`,
        { ...managerdata, facility: facility_Id, department: departmentdatas },
        { headers }
      );

      return response;
    } catch (error) {
      console.log(error);

      return error;
    }
  }
);

export const update_manager = createAsyncThunk(
  "update_manager",
  async ({ token, id, managerdata, date, departmentdatas, termination }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };
      //  const data = {
      //     name,position,
      //     is_terminated: is_terminated,
      // }
      const response = await axios.put(
        `${apiEndpoints.UPDATE_MANAGERS}/${id}/`,
        {
          ...managerdata,
          department: departmentdatas,
          termination_date: date,
          is_terminated: termination,
        },
        { headers }
      );

      return response;
    } catch (error) {
      console.log(error);

      return error;
    }
  }
);

export const getsinglemanager = createAsyncThunk(
  "getsinglemanager",
  async ({ token, id }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };

      const response = await axios.get(
        `${apiEndpoints.GET_SINGLE_MANAGER}/${id}`,
        { headers }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const managertateblank = createAsyncThunk(
  "managertateblank",
  async () => {
    try {
      let data = null;

      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);
