import React from "react";
import DashAside from "../dashboard/DashAside";
import DashboardHeader from "../dashboard/DashboardHeader";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const Support = (props) => {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  const { showSidebar } = useSelector((state) => state.hamburger);

  const cookie = new Cookies();
  const navigate = useNavigate();
  const token = cookie.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);
  return (
    <div className="dash-wrapper ">
      <div className="dashboard d-flex ">
        {showSidebar && <DashAside />}

        <div className="dash-main mx-5 mt-3" style={{ width: "100%" }}>
          <DashboardHeader />
          <h1>Support</h1>
        </div>
      </div>
    </div>
  );
};

export default Support;
