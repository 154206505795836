let baseUrl = "https://calloffcop.herokuapp.com"

const apiEndpoints = {
  LOGIN: `${baseUrl}/api/v1/users/login/`,
  FORGOT_PASSWORD_SEND_MAIL: `${baseUrl}/api/v1/users/password_reset/`,
  RESET_PASSWORD: `${baseUrl}/api/v1/users/password_reset/confirm/`,
  GET_FACILITY: `${baseUrl}/api/v1/facilities/fetch/`,
  GET_DASHBOARD_HEADER: `${baseUrl}/api/v1/facilities/dashboard/header/calloff`,
  GET_DASHBOARD_BODY: `${baseUrl}/api/v1/facilities/dashboard/body/calloff`,
  GET_DASHBOARD_CHART: `${baseUrl}/api/v1/facilities/dashboard/chart`,

  GET_EMPLOYEE: `${baseUrl}/api/v1/employee/filter`,
  ADD_EMPLOYEE: `${baseUrl}/api/v1/employee/add/`,
  UPDATE_EMPLOYEE: `${baseUrl}/api/v1/employee/update`,
  UPLOAD_EMPLOYEE: `${baseUrl}/api/v1/employee/bulk/upload/`,
  GET_SIGNLE_EMPLOYEE: `${baseUrl}/api/v1/employee/get`,

  GET_MANAGERS_LIST: `${baseUrl}/api/v1/facilities/filter`,
  UPDATE_MANAGERS: `${baseUrl}/api/v1/facilities/update/manager`,
  ADD_MANAGER: `${baseUrl}/api/v1/facilities/add/manager/`,
  GET_SINGLE_MANAGER: `${baseUrl}/api/v1/facilities/filter/manager`,
  GET_DEPARTMENT: `${baseUrl}/api/v1/facilities/fetch/departments`
};

export { apiEndpoints };