import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiEndpoints } from "../../Baseurl";

import { filterActions } from "../slice/filterslice";

export const getemployee = createAsyncThunk(
  "getemployee",
  async ({ token, is_terminated, id, name, position_name }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };
      const data = {
        is_terminated: is_terminated,
        position_name,
        name,
      };
      const response = await axios.post(
        `${apiEndpoints.GET_EMPLOYEE}/${id}/`,
        data,
        { headers }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addEmployee = createAsyncThunk(
  "addEmployee",
  async ({ token, facility_Id, employee }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };

      const data = {
        facility: facility_Id,
        phone: employee.phone,
        first_name: employee.first_name,
        last_name: employee.last_name,
        full_name: employee.full_name,
        is_terminated: employee.is_terminated,
        position: employee.emp_position,
        badge_id: employee.badge_id,
        termination_date: employee.termination_date,
      };
      const response = await axios.post(`${apiEndpoints.ADD_EMPLOYEE}`, data, {
        headers,
      });

      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const getsingleemployee = createAsyncThunk(
  "getsingleemployee",
  async ({ token, id }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };

      const response = await axios.get(
        `${apiEndpoints.GET_SIGNLE_EMPLOYEE}/${id}/`,
        { headers }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const uploademployee = createAsyncThunk(
  "uploademployee",
  async ({ token, facility_Id, uploadFile }) => {
    try {
      const headers = {
        Authorization: `Bearer ${token.data.access}`,
      };
      const data = {
        uploadFile,
        facility: facility_Id,
      };

      const formdata = new FormData();
      formdata.set("employee_file", uploadFile);
      formdata.set("facility", facility_Id);
      const response = await axios.post(
        `${apiEndpoints.UPLOAD_EMPLOYEE}`,
        formdata,
        {
          headers,
          onUploadProgress: (e) => {
            const val = Math.round((e.loaded / e.total) * 100);
            filterActions.changeporgress(val);
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const update_Employee = createAsyncThunk(
  "update_Employee",
  async ({ token, id, editemployee, date, is_termination }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.access}`,
      };
      const data = {
        facility: editemployee.facility_name,
        phone: editemployee.phone,
        first_name: editemployee.first_name,
        last_name: editemployee.last_name,
        full_name: editemployee.full_name,
        is_terminated: is_termination,
        position: editemployee.emp_position,
        badge_id: editemployee.badge_id,
        termination_date: date,
      };
      const response = await axios.put(
        `${apiEndpoints.UPDATE_EMPLOYEE}/${id}/`,
        data,
        { headers }
      );

      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const stateblank = createAsyncThunk("stateblank", async () => {
  try {
    let data = null;

    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
});
