import React, { useState } from "react";
import { Table } from "react-bootstrap";

import load from "../../assets/img/loading.gif";
import { useSelector } from "react-redux";
import Sort from "../../assets/img/Sort.png";
import Modal from "react-bootstrap/Modal";
const DashTable = ({ dataTable }) => {
  // modal
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tableDataLoading = useSelector(
    (state) => state.bodyfacilitydata.isLoading
  );

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedRows = [...dataTable].sort((a, b) => {
    const valueA = a[sortColumn]?.toLowerCase() || "";
    const valueB = b[sortColumn]?.toLowerCase() || "";

    return sortOrder === "asc"
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  });

  return (
    <>
      {!tableDataLoading ? (
        <div
          className="p-3 table_main"
          style={{ backgroundColor: "#2D333A", borderRadius: "10px" }}
        >
          <Table striped bordered hover style={{ backgroundColor: "#2D333A" }}>
            <thead style={{ backgroundColor: "#14181D" }}>
              <tr>
                <th className="ps-2">Call-Off Date</th>
                <th
                  onClick={() => {
                    handleSort("employee_full_name");
                  }}
                >
                  <img src={Sort} alt="aa" className="pointer" />
                  Name
                </th>

                <th>Reason</th>

                <th>Position</th>
              </tr>
            </thead>
            <tbody>
            {sortedRows.map((row, index) => {
                const callInDate = new Date(row.call_in_date);
                const utcDay = callInDate.getUTCDate().toString().padStart(2, '0');
                const utcMonth = (callInDate.getUTCMonth() + 1).toString().padStart(2, '0');
                const utcYear = callInDate.getUTCFullYear();
                const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;

                return (
                    <tr key={index}>
                        <td className="text-white ">
                            <span
                                className="pointer"
                                style={{
                                    backgroundColor: "#32BE30",
                                    padding: "6px 15px",
                                    borderRadius: "20px",
                                }}
                                onClick={() => {
                                    handleShow();
                                    setId(row.id);
                                }}
                            >
                                {formattedDate}
                                <span className="ms-2">{row.call_in_time}</span>
                            </span>
                        </td>
                        <td className="text-white">{row.employee_full_name}</td>
                        <td className="text-white">{row.reason}</td>
                        <td className="text-white">{row.position}</td>
                    </tr>
                );
            })}

            </tbody>
          </Table>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <img src={load} alt="loading" />
        </div>
      )}

      <Modal show={show} centered size="lg" onHide={handleClose}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#2D333A" }}
        ></Modal.Header>
        <Modal.Body style={{ backgroundColor: "#2D333A" }}>
          <Table striped bordered hover style={{ backgroundColor: "#2D333A" }}>
            <thead style={{ backgroundColor: "#14181D" }}>
              <tr>
                <th>Shift Date</th>
                <th className="mx-5">Shift</th>
              </tr>
            </thead>
            <tbody>
              {sortedRows.map((row, index) => {
                if (row.id === id){
                  const callOffDate = new Date(row.call_off_date);
                  const utcDay = callOffDate.getUTCDate().toString().padStart(2, '0');
                  const utcMonth = (callOffDate.getUTCMonth() + 1).toString().padStart(2, '0');
                  const utcYear = callOffDate.getUTCFullYear();
                  const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;
                    return (
                      <tr key={index}>
                        <td className="text-white ">
                          {formattedDate}
                        </td>
                        <td className="text-white">{row.shift}</td>
                      </tr>
                    );
                }
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashTable;

