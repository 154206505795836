import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import srch from "../../assets/img/search.png";
import dragImg from "../../assets/img/drag-vertical.png";
import teer from "../../assets/img/teer.png";
import "react-date-range/dist/styles.css"; // Import the styles
import "react-date-range/dist/theme/default.css"; // Import the theme
import Barchart from "./Barchart";
import { filterActions } from "../../redux/slice/filterslice";
import { DateRange } from "react-date-range";
import {
  dashboardChart,
  dashboardbodycallof,
} from "../../redux/action/facility";
import { Cookies } from "react-cookie";
import DashTable from "./DashTable";
import { subDays } from "date-fns"; // Import subDays from "date-fns" to calculate the date
import Dropdown from "react-bootstrap/Dropdown";
import { Divider } from "@mui/material";
const columns = [
  { field: "badge_id", headerName: "Badge ID", width: 70 },
  { field: "first_name", headerName: "First name", width: 130 },
  { field: "last_name", headerName: "Last name", width: 130 },
  { field: "full_name", headerName: "full_name", width: 130 },
  { field: "position", headerName: "position", width: 130 },
  { field: "is_terminated", headerName: "Termination", width: 130 },
  {
    field: "phone",
    headerName: "Phone Number",
    type: "number",
    width: 90,
    date: 5645,
  },
];

const DashFilters = () => {
  const dispatch = useDispatch();
  const cookie = new Cookies();
  const [lblsearch, setlblsearch] = useState("");

  let token = cookie.get("token");
  const [rows, setrows] = useState([]);
  const { bodycallofdata } = useSelector((state) => state.bodyfacilitydata);
  const [fillter_position, setfillter_position] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [value, setDateValue] = useState("");
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const { data } = useSelector((state) => state.Facility);

  const { facility_Id, position, searchName, search, date } = useSelector(
    (state) => state.filter
  );

  const lastSevenDayDate1 = new Date();
  lastSevenDayDate1.setDate(lastSevenDayDate1.getDate() - 7);
  const formattedStartDate1 = `${monthNames[lastSevenDayDate1.getMonth()]} ${lastSevenDayDate1.getDate()}`

  // Set the end date to today's date
  const todayDate = new Date();
  const formattedEndDate = `${monthNames[todayDate.getMonth()]} ${todayDate.getDate()}, ${todayDate.getFullYear()}`;

  const [chartstartDate, setchartstartDate] = useState(null);
  const [chartendDate, setchartendDate] = useState(null);
  const [startDate, setStartDate] = useState(formattedStartDate1); // Start date is set to today's date minus 7 days
  const [endDate, setEndDate] = useState(formattedEndDate); // End date is set to today's date

  const handleDateRangeChange = (item) => {
    setStartDate(
      `${monthNames[item.selection.startDate.getMonth()]} ${item.selection.startDate.getDate()}`
    );
    setEndDate(
      `${monthNames[item.selection.endDate.getMonth()]} ${item.selection.endDate.getDate()}, ${item.selection.endDate.getFullYear()}`
    );
    let startDateFormatted = convertdate(item.selection.startDate);
    let endDateFormatted = convertdate(item.selection.endDate);
    setchartstartDate(startDateFormatted);
    setchartendDate(endDateFormatted);
    dispatch(
      filterActions.changedate({
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      })
    );
    setRange([item.selection]);
  };

  useEffect(() => {
    if (data?.facilities.length) {
      let id;
      let sessionId = JSON.parse(sessionStorage.getItem("facility"));

      if (facility_Id == "") {
        id = sessionId
          ? sessionId.id
          : facility_Id
          ? facility_Id
          : data?.facilities[0]?.id;
        dispatch(
          filterActions.changeId(
            sessionId ? sessionId.id : data?.facilities[0]?.id
          )
        );
      } else {
        id = facility_Id;
      }
    }
  }, [data]);

  useEffect(() => {
    if (facility_Id) {
      dispatch(
        dashboardChart({
          token,
          id: facility_Id,
          start_date: date.start,
          end_date: date.end,
          search_name: search == "" ? null : search,
        })
      );

      dispatch(
        dashboardbodycallof({
          token,
          id: facility_Id,
          start_date: date.start,
          end_date: date.end,
          search_name: search,
          position_name: position,
        })
      );
    }
  }, [facility_Id, date.start, date.end, search, position]);

  useEffect(() => {
    setfillter_position(bodycallofdata?.positions);
  }, [bodycallofdata]);

  useEffect(() => {
    if (bodycallofdata) {
      setrows(bodycallofdata?.call_off_data);
    }
  }, [bodycallofdata]);

  var currentDate = new Date();

  var lastSevenDayDate = subDays(new Date(), 7);
  
  const formattedStartDate = `${monthNames[lastSevenDayDate.getMonth()]} ${lastSevenDayDate.getDate()}`;


  var currentDates = new Date();

  var year = currentDates.getFullYear();
  var month = currentDates.getMonth() + 1;
  var day = currentDates.getDate();

  var formattedDate1 = year + "-" + month + "-" + day;

  const convertdate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it's zero-based
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [sortColumn, setSortColumn] = useState("date"); // Initial sort column
  const [sortDirection, setSortDirection] = useState("asc"); // Initial sort direction
  const [sortColumn1, setSortColumn1] = useState("name");

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const dataTable = {
    rows,
  };
  const sdate = new Date(new Date().setDate(new Date().getDate() - 7));

  const [range, setRange] = useState([
    {
      startDate: sdate,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("  Filter Position");

  function handleBlur(e) {}
  return (
    <div className="container-fluid ">
      <div className="row mb-4">
        <div className="col-xl-2  col-lg-4 col-md-4 col-sm-6  ">
          <div className="bg-purplee center" style={{ height: "100%" }}>
            Call-Offs Total = {bodycallofdata?.total_call_off_count}
          </div>
        </div>
        <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0">
          <Dropdown className="dash-main-filter">
            <Dropdown.Toggle style={{ height: "100%", width: "100%" }}>
              <input
                className="date-range-input py-2 pointer ps-3"
                type="text"
                onClick={onToggle}
                value={`${startDate} - ${endDate}`}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <DateRange
                className="date-calander"
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={range}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="emp-search col-xl-3 col-lg-4 col-md-4 col-sm-6  mt-2 mt-md-0 ">
          <div className="emp-search-in pe-2 px-2">
            <img src={srch} alt="" />
            <input
              value={lblsearch}
              onChange={(e) => {
                setlblsearch(e.target.value);
                if (e.target.value.length >= 3) {
                  dispatch(filterActions.changesearch(e.target.value));
                }
                if (e.target.value === "") {
                  dispatch(filterActions.changesearch(""));
                }
              }}
              type="text"
              placeholder="search here"
              className="ms-3 text-white placeholder"
              style={{ color: "white" }}
            />
          </div>
        </div>
        <div className=" col-xl-2 col-lg-4 col-md-4 col-sm-6 mt-2 mt-xl-0 ">
          <Dropdown>
            <Dropdown.Toggle
              style={{ height: "100%", width: "100%" }}
              className="focus-none"
            >
              <img src={teer} /> {selected}
            </Dropdown.Toggle>

            <Dropdown.Menu className="filter-item px-4">
              {fillter_position?.map((it) => {
                return (
                  <>
                    <Dropdown.Item
                      className=" filter-items"
                      onClick={(e) => {
                        setIsSelected(e.target.textContent);
                        dispatch(
                          filterActions.changeposition(e.target.textContent)
                        );
                        setIsActive(!isActive);
                      }}
                    >
                      <img src={dragImg} className="me-2" />
                      <span className="text-white"> {it.position__name}</span>
                    </Dropdown.Item>
                    <Divider className="bg-white my-1" />
                  </>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="clear-filter col-xl-2  col-lg-4 col-md-4 col-sm-6  mt-2 mt-xl-0">
          <button
            onClick={() => {
              dispatch(filterActions.changeposition(""));
              dispatch(filterActions.changesearch(""));
              setlblsearch("");
              setIsSelected("Filter Position");
              setEndDate(formattedEndDate);
              setStartDate(formattedStartDate);
              setRange([
                {
                  startDate: sdate,
                  endDate: new Date(),
                  key: "selection",
                },
              ]);
              let startDateFormatted = convertdate(lastSevenDayDate);
              let endDateFormatted = convertdate(currentDate);
              dispatch(
                filterActions.changedate({
                  startDate: startDateFormatted,
                  endDate: endDateFormatted,
                })
              );
              setchartendDate(null);
              setchartstartDate(null);
            }}
          >
            Clear Filter
          </button>
        </div>
      </div>
      <div className="row dashboardcmp">
        <div className="emp-data-table col-xl-7">
          <div style={{ width: "100%" }}>
            {/* {data && <DataGrid rows={rows} columns={columns} />} */}
            {/* <MDBDataTable striped bordered small data={dataTable} /> */}
            <DashTable dataTable={rows} />
          </div>
        </div>
        <div
          className="col-xl-5 mt-3 mt-xl-0"
          // style={{ backgroundColor: "#2D333A", borderRadius: "10px" }}
          // style={{ width: "40%"}}
        >
          <div
            className="p-4  "
            style={{
              backgroundColor: "#2D333A",
              borderRadius: "10px",
              width: "100%",
            }}
          >
            <Barchart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashFilters;
