import React from "react";

import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Switch from "@mui/material/Switch";
import { useState } from "react";

import loginloader from "../../assets/img/loading.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";

import { GrClose } from "react-icons/gr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Table } from "react-bootstrap";
import Sort from "../../assets/img/Sort.png";

import load from "../../assets/img/loading.gif";
import Modal from "react-bootstrap/Modal";
import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from "@mui/material";
// import { LoadingButton } from "@mui/lab";
import {
  addEmployee,
  getemployee,
  getsingleemployee,
  stateblank,
  update_Employee,
} from "../../redux/action/employee";
import { employeeAction, employeeActions } from "../../redux/slice/employee";
const label = { inputProps: { "aria-label": "Switch demo" } };

const DataTable = ({ rows }) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
    // if (sortColumn !== column) {
    //   setSortColumn(column);
    //   setSortOrder([...rows].sort((a, b) => a[column].localeCompare(b[column])));
    // } else {
    //   setSortColumn("");
    //   setSortOrder([...rows].reverse());
    // }
  };

  const sortedRows = [...rows].sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (valueA < valueB) {
      return sortOrder === "asc" ? -1 : 1;
    } else if (valueA > valueB) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  const sortRows = () => {
    if (!sortColumn) return [...sortedRows]; // No sorting if no column is selected

    const sorted = [...sortedRows].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (typeof valueA === "string" && typeof valueB === "string") {
        // String sorting (word-wise)
        const lowercaseA = valueA.toLowerCase();
        const lowercaseB = valueB.toLowerCase();

        if (sortOrder === "asc") {
          return lowercaseA.localeCompare(lowercaseB);
        } else {
          return lowercaseB.localeCompare(lowercaseA);
        }
      } else if (valueA instanceof Date && valueB instanceof Date) {
        // Date sorting
        if (sortOrder === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      } else {
        // For other data types, return 0 to maintain the original order
        return 0;
      }
    });

    return sorted;
  };

  const sortedAndFilteredRows = sortRows();
  const location = useLocation();
  // edit modal
  const [showEdit, setshowEdit] = useState(false);
  const handleEditClose = () => {
    seteditemployee({});
    setshowEdit(false);
    setis_termination(false);
    dispatch(stateblank());
  };
  const [editemployee, seteditemployee] = useState({});
  const handleEditShow = () => {
    setshowEdit(true);
  };

  const [is_termination, setis_termination] = useState(false);

  const [termination, settermination] = useState(false);
  const [search, setsearch] = useState("");
  const [position, setposition] = useState("");
  const cookie = new Cookies();
  const navigate = useNavigate();
  const token = cookie.get("token");
  const { data } = useSelector((state) => state.Facility);
  const { facility_Id } = useSelector((state) => state.filter);
  const {
    data: empdata,
    addemployee,
    addemployeeLoading,

    update_data,
    isLoading,
  } = useSelector((state) => state.employee);
  const { bodycallofdata } = useSelector((state) => state.bodyfacilitydata);
  const dispatch = useDispatch();
  let sessionId = JSON.parse(sessionStorage.getItem("facility"));

  const [id, setid] = useState("");
  const [fillter_position, setfillter_position] = useState([]);
  useEffect(() => {
    setfillter_position(bodycallofdata?.positions);
  }, [bodycallofdata]);

  const [startDate, setStartDate] = useState(new Date());
  const [date, setdate] = useState(new Date());

  const [employee, setemployee] = useState({
    first_name: "",
    last_name: "",
    facility_name: facility_Id ? facility_Id : data?.facilities[0]?.id,
    full_name: "",
    is_terminated: false,
    badge_id: "",
    emp_position: "",
    phone: "",
    termination_date: date,
  });

  useEffect(() => {
    if (update_data?.response?.status === 400 && update_data !== null) {
      let arr = [];
      let err = update_data?.response?.data;
      for (const i in err) {
        let msg = i + " " + err[i];
        arr.push(msg);
      }

      toast.error(arr[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (update_data?.status === 200 && update_data !== null) {
      dispatch(
        getemployee({
          token,
          is_terminated: false,
          id: facility_Id,
          name: "",
          position_name: "",
        })
      );
      setis_termination(false);
      setshowEdit(false);
      dispatch(stateblank());
      toast.success("sucessfully updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [update_data]);

  useEffect(() => {
    seteditemployee({
      first_name: addemployee?.first_name ? addemployee.first_name : "",
      last_name: addemployee?.last_name ? addemployee.last_name : "",
      facility_name: addemployee?.facility,
      full_name: addemployee?.full_name,
      is_terminated: addemployee?.is_terminated,
      badge_id: addemployee?.badge_id,
      emp_position: addemployee?.position,
      phone: addemployee?.phone,
    });
    const inputDate = new Date(addemployee?.termination_date);

    setis_termination(addemployee?.is_terminated);
    // Get the date components
    const day = inputDate.getUTCDate();
    const month = inputDate.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = inputDate.getUTCFullYear();

    // Format the date in the desired format
    const simpleDate = `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;

    const inputDateString = simpleDate;

    // Split the input date string into month, day, and year components
    const [months, days, years] = inputDateString.split("/");

    // Create a new Date object from the parsed components
    const inputDates = new Date(`${years}-${months}-${days}`);

    // Get the number of milliseconds since the Unix Epoch
    const millisecondsSinceEpoch = inputDates.getTime();

    setStartDate(millisecondsSinceEpoch);
    setdate(addemployee?.termination_date);
  }, [addemployee]);

  const handleEditChange = (e) => {
    //   if (e.target.name === "is_terminated") {
    //     return seteditemployee({
    //       ...editemployee,
    //       [e.target.name]: e.target.checked,
    //     });
    //   }

    seteditemployee({ ...editemployee, [e.target.name]: e.target.value });
  };

  return (
    <>
      {!isLoading ? (
        <div
          className="p-3 table_main"
          style={{ backgroundColor: "#2D333A", borderRadius: "10px" }}
        >
          <Table striped bordered hover>
            <thead style={{ backgroundColor: "#14181D" }}>
              <tr>
                <th className="ps-3">Badge ID</th>
                <th onClick={() => handleSort("full_name")}>
                  {" "}
                  <img src={Sort} alt="a" className="pointer" />
                  Full Name
                </th>
                <th onClick={() => handleSort("first_name")}>
                  {" "}
                  <img src={Sort} alt="a" className="pointer" />
                  First Name
                </th>
                <th onClick={() => handleSort("last_name")}>
                  {" "}
                  <img src={Sort} alt="aa" className="pointer" />
                  Last Name
                </th>
                <th onClick={() => handleSort("position_name")}>
                  <img src={Sort} alt="a" className="pointer" />
                  Position
                </th>
                <th>Phone Number</th>

                {location?.state?.termination ? (
                  <th onClick={() => handleSort("termination_date")}>
                    <img src={Sort} alt="aa" className="pointer" /> Termination
                    Date
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredRows.map((row) => (
                <tr key={row.badge_id}>
                  <td
                    style={{ color: "#8640df" }}
                    className="ps-3 pointer"
                    onClick={() => {
                      handleEditShow();
                      setid(row?.id);
                      dispatch(getsingleemployee({ token, id: row?.id }));
                    }}
                  >
                    {row.badge_id}
                  </td>
                  <td>{row.full_name ? row.full_name : "N-A"}</td>
                  <td>{row.first_name}</td>
                  <td>{row.last_name}</td>
                  <td>{row.position_name}</td>
                  <td>{row.phone}</td>
                  {row.is_terminated ? (
                    <td>
                      {row.termination_date ? row.termination_date : "N-A"}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {/* edit modal */}

          <Modal
            show={showEdit}
            onHide={handleEditClose}
            // backdrop="static"
            keyboard={false}
            size="lg"
            centered
          >
            <Modal.Body>
              {!addemployee ? (
                <img className="gif" src={loginloader} />
              ) : (
                <div className="text-dark container-fluid">
                  <div className="row">
                    <div className="col-11">
                      <h3>Edit Employee</h3>
                    </div>
                    <div className="col-1">
                      <GrClose onClick={handleEditClose} className="pointer" />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <FormControl size="" className="form-control">
                        <label htmlFor="badge">Badge ID</label>
                        <input
                          className="form-control input-11"
                          id="outlined-basic"
                          type="number"
                          placeholder="Badge Id"
                          variant="outlined"
                          value={editemployee?.badge_id}
                          name="badge_id"
                          onChange={handleEditChange}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <p> Termination Status</p>
                      <Switch
                        {...label}
                        // value={termination}
                        checked={is_termination ? "checked" : ""}
                        onChange={(e) => {
                          setis_termination(e.target.checked);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      {is_termination ? (
                        <FormControl size="" className="form-control">
                          <label htmlFor="date">Termination Date</label>
                          <DatePicker
                            className=" date-emp"
                            name="termination_date"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setdate(date);
                            }}
                          />
                        </FormControl>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <Divider className="my-4"></Divider>
                  <div className="row">
                    <div className="col-6">
                      <FormControl size="" className="form-control">
                        <label htmlFor="f-name">First Name</label>
                        <input
                          className="form-control input-11"
                          id="outlined-basic"
                          placeholder="First Name"
                          variant="outlined"
                          value={editemployee?.first_name}
                          name="first_name"
                          onChange={handleEditChange}
                        />
                      </FormControl>
                    </div>
                    <div className="col-6">
                      <FormControl size="" className="form-control">
                        <label htmlFor="l-name">Last Name</label>
                        <input
                          className="form-control input-11"
                          id="outlined-basic"
                          placeholder="Last Name"
                          variant="outlined"
                          value={editemployee?.last_name}
                          name="last_name"
                          onChange={handleEditChange}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="row mt-3"></div>

                  <div className="row mt-3">
                    <div className="col-6">
                      <FormControl size="small" className="form-control">
                        <label htmlFor="positoln">Position</label>
                        {/* <InputLabel id="demo-select-small-label">
                        Position
                      </InputLabel> */}
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          className="form-control"
                          value={parseInt(editemployee?.emp_position)}
                          name="emp_position"
                          onChange={handleEditChange}
                          label="Age"
                        >
                          {fillter_position?.map((it, i) => {
                            return (
                              <MenuItem value={i + 1}>
                                {it.position__name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-6">
                      <FormControl size="" className="form-control">
                        <label htmlFor="Phone">Phone</label>
                        <input
                          className="form-control input-11"
                          id="outlined-basic"
                          placeholder="Phone"
                          type="number"
                          variant="outlined"
                          name="phone"
                          value={editemployee?.phone}
                          onChange={handleEditChange}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col">
                      <button
                        //   loading={!editLoading}
                        onClick={() => {
                          dispatch(
                            update_Employee({
                              token,
                              id,
                              editemployee,
                              date,
                              is_termination,
                            })
                          );
                        }}
                        className=" btn-purple  text-white"
                      >
                        {addemployeeLoading ? (
                          <img className="gif" src={loginloader} />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
          {/*edit modal over */}
          <ToastContainer />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <img src={load} alt="loading" />
        </div>
      )}
    </>
  );
};

export default DataTable;
