import React from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import load from "../../assets/img/loading.gif";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: "white", // Change the label font color to white
      },
    },
    x: {
      ticks: {
        color: "white", // Change the label font color to white
      },
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Barchart = () => {
  const state = useSelector((state) => state);
  if (state.bodyfacilitydata.bodycallofdata != null && state.bodyfacilitydata.bodycallofdata != undefined) {
    var chartData = Object.values(state.bodyfacilitydata.bodycallofdata.chart_data);
  }
  const data = {
    labels,
    datasets: [
      {
        label: "Count of Call-Offs",
        data: chartData,
        backgroundColor: "#BB88FD",
      },
    ],
  };
  return (
    <div
      className=" p-3 "
      style={{ backgroundColor: "#14181D", borderRadius: "10px" }}
    >
      {state?.chart?.isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <img src={load} alt="loading" />
        </div>
      ) : (
        <Bar options={options} data={data} height={270} />
      )}
    </div>
  );
};

export default Barchart;
