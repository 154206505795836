import { createSlice } from "@reduxjs/toolkit"
import { addEmployee, getemployee, getsingleemployee, stateblank, update_Employee, uploademployee, uploadfile } from "../action/employee"


const getEmployee = createSlice({
    name: "employee",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        addemployee: null,
        addemployeeError: null,
        addemployeeLoading: false,
        uploademployeedata: null,
        update_data: null

    },
    extraReducers: (builder) => {
        builder.addCase(getemployee.fulfilled, (state, action) => {
            state.data = action.payload
            state.isLoading = false
        })
        builder.addCase(getemployee.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getemployee.rejected, (state, action) => {
            state.isError = true
            state.isLoading = false
        })

        builder.addCase(addEmployee.fulfilled, (state, action) => {
            state.addemployee = action.payload
            state.addemployeeLoading = false
        })
        builder.addCase(addEmployee.pending, (state, action) => {
            state.addemployeeLoading = true
        })
        builder.addCase(addEmployee.rejected, (state, action) => {
            state.addemployeeError = action.payload
            state.addemployeeLoading = false
        })
        builder.addCase(getsingleemployee.fulfilled, (state, action) => {
            state.addemployee = action.payload
            state.addemployeeLoading = false
        })
        builder.addCase(getsingleemployee.pending, (state, action) => {
            state.addemployeeLoading = false
        })
        builder.addCase(getsingleemployee.rejected, (state, action) => {
            state.isError = true
            state.addemployeeLoading = false
        })


        builder.addCase(uploademployee.fulfilled, (state, action) => {
            state.uploademployeedata = action.payload
            state.addemployeeLoading = false
        })
        builder.addCase(uploademployee.pending, (state, action) => {
            state.addemployeeLoading = true
        })
        builder.addCase(uploademployee.rejected, (state, action) => {
            state.isError = true
            state.addemployeeLoading = false
        })

        builder.addCase(update_Employee.fulfilled, (state, action) => {
            state.update_data = action.payload
            state.addemployeeLoading = false
        })
        builder.addCase(update_Employee.pending, (state, action) => {
            state.addemployeeLoading = true
        })
        builder.addCase(update_Employee.rejected, (state, action) => {
            state.isError = true
            state.update_data = false
        })

        builder.addCase(stateblank.fulfilled, (state, action) => {
            state.update_data = action.payload
            state.addemployeeLoading = false
            state.addemployee = action.payload
        })
        builder.addCase(stateblank.pending, (state, action) => {
            state.addemployeeLoading = true
        })
        builder.addCase(stateblank.rejected, (state, action) => {
            state.isError = true
            state.update_data = action.payload
            state.addemployee = action.payload
        })
    },

})

export const employeeActions = getemployee.actions
export default getEmployee.reducer
