import React, { useEffect, useState } from "react";
import dots from "../../assets/img/drag-vertical.png";
import right from "../../assets/img/right.png";
import drop from "../../assets/img/dropdown.png";
import { useDispatch, useSelector } from "react-redux";
import { getemployee } from "../../redux/action/employee";
import {
  dashboardChart,
  dashboardHeadercallof,
  dashboardbodycallof,
} from "../../redux/action/facility";
import { Cookies } from "react-cookie";
import { filterActions } from "../../redux/slice/filterslice";
import { itIT } from "@mui/material/locale";
import Dropdown from "react-bootstrap/Dropdown";
const DashDropdown = () => {
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.Facility);

  const { facility_Id, position, searchName, date } = useSelector(
    (state) => state.filter
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const token = cookie.get("token");

  // useEffect(() => {
  //   if (data?.facilities.length) {
  //     let id;
  //     let sessionId = JSON.parse(sessionStorage.getItem("facility"));

  //     if (facility_Id == "") {
  //       id = sessionId
  //         ? sessionId.id
  //         : facility_Id
  //         ? facility_Id
  //         : data?.facilities[0]?.id;
  //       dispatch(
  //         filterActions.changeId(
  //           sessionId ? sessionId.id : data?.facilities[0]?.id
  //         )
  //       );
  //     } else {
  //       id = facility_Id;
  //     }
  //   }
  // }, [data]);

  useEffect(() => {
    if (facility_Id) {
      // dispatch(
      //   dashboardChart({
      //     token,
      //     id: facility_Id,
      //     start_date: date.start,
      //     end_date: date.end,
      //   })
      // );
      // dispatch(
      //   dashboardbodycallof({
      //     token,
      //     id: facility_Id,
      //     start_date: date.start,
      //     end_date: date.end,
      //     search_name: "",
      //     position_name: position,
      //   })
      // );
      dispatch(dashboardHeadercallof({ token, id: facility_Id }));
    }
  }, [facility_Id, date.start, date.end]);

  const state = useSelector((state) => state);

  let facilityName = "";
  var facilitiesData = state.Facility.data;
  if (facilitiesData != null && facilitiesData != undefined) {
    for (const entry of facilitiesData.facilities) {
      if (
        entry.id === facility_Id &&
        !JSON.parse(sessionStorage.getItem("facility"))
      ) {
        facilityName = entry.name.toUpperCase();
        break;
      } else {
        let storage = JSON.parse(sessionStorage.getItem("facility"));
        facilityName = storage?.name?.toUpperCase();
        break;
      }
    }
  }

  return (
    <div className="">
      <Dropdown>
        <Dropdown.Toggle className="user-drop focus-none">
          <img src={drop} alt="" className="me-2" />
          {facilityName}
        </Dropdown.Toggle>

        <Dropdown.Menu className="user-drop-menu p-2">
          {data &&
            data.facilities.map((it) => {
              const isActive = it.id === state.filter.facility_Id; // Check if the current ID is selected
              return (
                //
                <Dropdown.Item
                  key={it.id}
                  id={it.id}
                  // className={`btn-content ${isActive ? "active" : ""}`}
                  // active={isActive}
                  className="user-drop-item"
                  onClick={(e) => {
                    sessionStorage.setItem(
                      "facility",
                      JSON.stringify({ id: it.id, name: it.name })
                    );
                    dispatch(filterActions.changeId(it.id));
                    setShowDropdown(!showDropdown);
                  }}
                >
                  <button
                    className={`btn hover-user ${isActive ? "active" : ""}`}
                    id={it.id}
                  >
                    <img src={dots} alt="dot" className="" id={it.id} />
                    <span className="white mx-4" id={it.id}>
                      {it.name}
                    </span>
                  </button>
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      {/* <div
        className="dropdown-header"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        <span>
          <img src={drop} alt="" className="me-2" />
          {facilityName}
        </span>
        {showDropdown ? (
          <div className="dropdown-content d-flex flex-column align-items-start">
            {data &&
              data.facilities.map((it) => {
                const isActive = it.id === state.filter.facility_Id; // Check if the current ID is selected
                return (
                  <div
                    key={it.id}
                    id={it.id}
                    className={`btn-content ${isActive ? "active" : ""}`}
                    onClick={(e) => {
                      sessionStorage.setItem(
                        "facility",
                        JSON.stringify({ id: it.id, name: it.name })
                      );
                      dispatch(filterActions.changeId(it.id));
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <button
                      className={`btn ${isActive ? "active" : ""}`}
                      id={it.id}
                    >
                      <img src={dots} alt="dot" className="" id={it.id} />
                      <span className="white mx-4" id={it.id}>
                        {it.name}
                      </span>
                     
                    </button>
                  </div>
                );
              })}
          </div>
        ) : null}
      </div> */}
    </div>
  );
};

export default DashDropdown;
