import React from "react";
import { BiChevronDown } from "react-icons/bi";
import DashAside from "../dashboard/DashAside";
import DashboardHeader from "../dashboard/DashboardHeader";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import searchimg from "../../assets/img/search.png";
import fileup from "../../assets/img/file.png";
import dragImg from "../../assets/img/drag_sk.svg";
import success_csv from "../../assets/img/success_csv.png";
import success from "../../assets/img/success.png";
import teer from "../../assets/img/teer.svg";
import { MDBDataTable } from "mdbreact";
import loginloader from "../../assets/img/loading.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import sort from "../../assets/img/Sort.png";
import Breadcrumb from "../dashboard/Breadcrumb";
import { GrClose } from "react-icons/gr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiUpload } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { IoIosAddCircle } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import DataTable from "../dashboard/DataTable";
import Dropdown from "react-bootstrap/Dropdown";

import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  addEmployee,
  getemployee,
  getsingleemployee,
  stateblank,
  uploademployee,
} from "../../redux/action/employee";
import axios from "axios";
import { apiEndpoints } from "../../Baseurl";
import { dashboardbodycallof } from "../../redux/action/facility";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Employee = (props) => {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  const { date } = useSelector((state) => state.filter);
  // drag and drop file handler
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setprogress] = useState(0);
  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  // Handle drag and drop events

  const handledownload = () => {
    const currentHost = window.location.host;
    const downloadUrl = `${window.location.protocol}//${currentHost}/employee.csv`; // Use window.location.protocol
    console.log(downloadUrl);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "employee.csv"; // Set the desired filename
    a.click();
};

  const uploademployeeFile = async (token, facility_Id, uploadFile) => {
    try {
      setdragdropshow(true);
      const headers = {
        Authorization: `Bearer ${token.data.access}`,
      };
      const data = {
        uploadFile,
        facility: facility_Id,
      };

      const formdata = new FormData();
      formdata.set("employee_file", uploadFile);
      formdata.set("facility", facility_Id);
      const response = await axios.post(
        `${apiEndpoints.UPLOAD_EMPLOYEE}`,
        formdata,
        {
          headers,
          onUploadProgress: (e) => {
            const val = Math.round((e.loaded / e.total) * 100);
            setprogress(Math.round((e.loaded / e.total) * 100));
          },
        }
      );

      if (response.status === 200) {
        setprogress(0);
        handleUploadClose();
        dispatch(
          getemployee({
            token,
            is_terminated: false,
            id: facility_Id,
            name: "",
            position_name: "",
          })
        );
        toast.success("Successfully uploaded", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {}
  };

  const handleDragEnter = (event) => {
    event.preventDefault();

    event.stopPropagation();
    event.currentTarget.classList.add("dragover");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove("dragover");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove("dragover");
    const file = event.dataTransfer.files[0];

    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "csv") {
        const files = event.dataTransfer.files[0];

        setSelectedFile(files);
        setuploadFile(files);
      } else {
        // alert('Please drop a CSV file.');
        toast.error("Please drop a CSV file.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  // over
  const [termination, settermination] = useState(true);
  const [search, setsearch] = useState("");
  const [position, setposition] = useState("");
  const cookie = new Cookies();
  const navigate = useNavigate();
  const token = cookie.get("token");
  const { data } = useSelector((state) => state.Facility);
  const { facility_Id } = useSelector((state) => state.filter);
  const {
    data: empdata,
    addemployee,
    addemployeeLoading,
    addemployeeError,
    uploademployeedata,
  } = useSelector((state) => state.employee);
  const { bodycallofdata } = useSelector((state) => state.bodyfacilitydata);
  const dispatch = useDispatch();
  let sessionId = JSON.parse(sessionStorage.getItem("facility"));

  useEffect(() => {
    if (data?.facilities.length) {
      let id = facility_Id ? facility_Id : data?.facilities[0]?.id;

      dispatch(
        getemployee({
          token,
          is_terminated: termination ? false : true,
          id,
          name: search,
          position_name: position,
        })
      );
    }
  }, [data, termination, search, position, facility_Id]);

  useEffect(() => {
    if (facility_Id) {
      dispatch(
        dashboardbodycallof({
          token,
          id: facility_Id,
          start_date: date.start,
          end_date: date.end,
          search_name: search,
          position_name: position,
        })
      );
    }
  }, [facility_Id]);

  useEffect(() => {
    if (addemployee?.response?.status === 400) {
      let arr = [];
      let err = addemployee?.response?.data;
      for (const i in err) {
        let msg = i + " " + err[i];
        arr.push(msg);
      }
      toast.error(`${arr[0]} `, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(stateblank());
    }
    if (addemployee?.status === 200) {
      toast.success("successfully Inserted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(
        getemployee({
          token,
          is_terminated: false,
          id: facility_Id,
          name: "",
          position_name: "",
        })
      );
      dispatch(stateblank());
      setShowAddNew(false);
    }
  }, [addemployee]);

  const [rows, setrows] = useState([]);
  useEffect(() => {
    if (empdata) {
      setrows(empdata);
    }
  }, [empdata]);

  const [fillter_position, setfillter_position] = useState([]);
  useEffect(() => {
    setfillter_position(bodycallofdata?.positions);
  }, [bodycallofdata]);

  const [startDate, setStartDate] = useState(new Date());

  const [employee, setemployee] = useState({
    first_name: "",
    last_name: "",
    facility_name: sessionId
      ? sessionId.id
      : facility_Id
      ? facility_Id
      : data?.facilities[0]?.id,
    full_name: "",
    is_terminated: false,
    badge_id: "",
    emp_position: "",
    phone: "",
    termination_date: startDate,
  });

  const [editemployee, seteditemployee] = useState({
    first_name: addemployee?.first_name ? addemployee.first_name : "",
    last_name: addemployee?.last_name ? addemployee.last_name : "",
    facility_name: addemployee?.facility,
    full_name: addemployee?.full_name,
    is_terminated: addemployee?.is_terminated,
    badge_id: addemployee?.badge_id,
    emp_position: addemployee?.position,
    phone: addemployee?.phone,
  });

  const [uploadFile, setuploadFile] = useState(null);

  const handleEditChange = (e) => {
    if (e.target.name === "is_terminated") {
      return seteditemployee({
        ...editemployee,
        [e.target.name]: e.target.checked,
      });
    }
    seteditemployee({ ...editemployee, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    if (e.target.name === "is_terminated") {
      return setemployee({ ...employee, [e.target.name]: e.target.checked });
    }
    setemployee({ ...employee, [e.target.name]: e.target.value });
  };

  // add modal
  const [showAddNew, setShowAddNew] = useState(false);
  const handleAddClose = () => {
    dispatch(stateblank());
    setShowAddNew(false);
  };
  const handleAddShow = () => setShowAddNew(true);
  const [lblsearch, setlblsearch] = useState("");
  // edit modal
  const [showEdit, setshowEdit] = useState(false);
  const handleEditClose = () => setshowEdit(false);
  const handleEditShow = () => setshowEdit(true);
  //
  const [showUpload, setshowUpload] = useState(false);
  const handleUploadClose = () => {
    setSelectedFile(null);
    setdragdropshow(false);
    setuploadFile("");
    setprogress(0);
    setshowUpload(false);
  };
  const handleUploadShow = () => setshowUpload(true);
  //
  const [sortColumn, setSortColumn] = useState("fname"); // Initial sort column
  const [sortDirection, setSortDirection] = useState("asc"); // Initial sort direction
  const [sortColumn1, setSortColumn1] = useState("lname");
  const [sortColumn2, setSortColumn2] = useState("date");
  const [dragdropshow, setdragdropshow] = useState(false);
  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const rowClassName = (row) => {
    if (row.name === "John") {
      return "custom-row-color"; // Apply custom style to row with name 'John'
    }
    return ""; // No custom style for other rows
  };

  const datatable = {
    columns: [
      {
        label: "Badge ID",
        field: "badge_id",
        sort: "asc",
        width: 200,
      },
      {
        label: (
          <>
            {sortColumn === "fname" && (
              <div>
                {" "}
                <img src={sort} style={{ marginRight: "2px" }} />
                First Name
              </div>
            )}
          </>
        ),
        field: "first_name",
        sort: "asc",
        width: 150,
        formatter: (value) => <span style={{ color: "red" }}>{value}</span>,
      },
      {
        label: (
          <>
            {sortColumn1 === "lname" && (
              <div>
                {" "}
                <img src={sort} style={{ marginRight: "2px" }} />
                Last Name
              </div>
            )}
          </>
        ),
        field: "last_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Position",
        field: "position_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone Number",
        field: "phone",
        sort: "asc",
        width: 100,
      },
      {
        label: (
          <>
            {sortColumn2 === "date" && (
              <div>
                <img src={sort} style={{ marginRight: "2px" }} />
                Termination Date
              </div>
            )}
          </>
        ),
        field: "termination_date",
        sort: "asc",
        width: 150,
      },
    ],
    rows,
  };
  const { showSidebar } = useSelector((state) => state.hamburger);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("  Filter Position");

  return (
    <div className="dash-wrapper ">
      <div className="dashboard d-flex ">
        {showSidebar && <DashAside />}

        <div className="dash-main mx-xl-5 mx-2 mt-3" style={{ width: "100%" }}>
          <DashboardHeader />
          <Breadcrumb name={"Employee"} link={"/employee"} />
          <div className="employee-dash container-fluid">
            <div className="filter-emp row">
              <div className="switch-tgle  col-xl-1 col-lg-2 col-md-4 col-sm-6 ">
                {" "}
                <Switch
                  {...label}
                  // value={termination}
                  checked={termination ? "checked" : ""}
                  onChange={(e) => {
                    settermination(e.target.checked);
                    navigate("/employees", {
                      state: { termination: e.target.checked ? false : true },
                    });
                  }}
                />
                Active
              </div>
              <div className="emp-search col-xl-3 col-lg-4 col-md-4 col-sm-6  mt-2 mt-md-0">
                <div className="emp-search-in pe-2 px-2">
                  <img src={searchimg} />
                  <input
                    type="text"
                    placeholder="search here"
                    className="ms-3 placeholder"
                    value={lblsearch}
                    onChange={(e) => {
                      setlblsearch(e.target.value);
                      if (e.target.value.length >= 3) {
                        return setsearch(e.target.value);
                      }
                      if (e.target.value === "") {
                        return setsearch(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="filter-position col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-2 mt-lg-0 ">
                <Dropdown>
                  <Dropdown.Toggle
                    style={{ height: "100%", width: "100%" }}
                    className="focus-none"
                  >
                    <img src={teer} /> {selected}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-item px-4">
                    {fillter_position?.map((it) => {
                      return (
                        <>
                          <Dropdown.Item
                            className=" filter-items"
                            onClick={(e) => {
                              setIsSelected(e.target.textContent);
                              setposition(e.target.textContent);
                              setIsActive(!isActive);
                            }}
                          >
                            <img src={dragImg} className="me-2" />
                            <span className="text-white">
                              {it.position__name}
                            </span>
                          </Dropdown.Item>
                          <Divider className="bg-white my-1" />
                        </>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div
                className="clear-filter col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-2 mt-lg-0"
                onClick={() => {
                  setsearch("");
                  setposition("");
                  settermination(true);
                  setlblsearch("");
                  setIsSelected("Filter Position");
                }}
              >
                <button>Clear Filter</button>
              </div>
              <div className="upload-employees col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-2 mt-xl-0 ">
                <FiUpload
                  fontSize={25}
                  color="#8640df"
                  onClick={handleUploadShow}
                  className="pointer"
                />
                <span className="mx-2 my-2 "> Upload Employees</span>
                <BiErrorCircle
                  fontSize={25}
                  onClick={handledownload}
                  className="pointer"
                />
              </div>
              <div
                className="add-employees col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-2 mt-xl-0 pointer"
                onClick={() => {
                  handleAddShow();
                }}
              >
                <div>
                  <IoIosAddCircle fontSize={25} color="black" />
                  <span className="ms-2 "> Add New</span>
                </div>
              </div>
            </div>
            <div className="emp-data-table col-xl-12">
              <div style={{ width: "100%" }}>
                <DataTable rows={rows} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add new modal */}
      <Modal
        show={showAddNew}
        onHide={handleAddClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="text-dark container-fluid">
            <div className="row">
              <div className="col-11">
                <h3>Add Employee</h3>
              </div>
              <div className="col-1">
                <GrClose onClick={handleAddClose} className="pointer" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <FormControl size="" className="form-control">
                  <TextField
                    type="number"
                    id="outlined-basic"
                    label="Badge Id"
                    variant="outlined"
                    name="badge_id"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
            </div>
            <Divider className="my-4"></Divider>
            <div className="row">
              <div className="col-6">
                <FormControl size="" className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    name="first_name"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
              <div className="col-6">
                <FormControl size="" className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    name="last_name"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
            </div>
            <div className="row mt-3"></div>

            <div className="row mt-3">
              <div className="col-6">
                <FormControl size="small" className="form-control">
                  <InputLabel id="demo-select-small-label">Position</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    className="form-control"
                    // value={age}
                    name="emp_position"
                    onChange={handleChange}
                    label="Age"
                    // onChange={handleChange}
                  >
                    {fillter_position?.map((it, i) => {
                      return (
                        <MenuItem value={i + 1}>{it.position__name}</MenuItem>
                      );
                    })}
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
            
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </div>

              <div className="col-6">
                <FormControl size="" className="form-control">
                  <TextField
                    id="outlined-basic"
                    label="Phone"
                    type="number"
                    variant="outlined"
                    name="phone"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <button
                  onClick={() => {
                    dispatch(addEmployee({ token, facility_Id, employee }));
                  }}
                  className={`btn-purple  text-white ${
                    addemployeeLoading ? `p0` : ""
                  }`}
                >
                  {addemployeeLoading ? (
                    <img className="gif" src={loginloader} />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* add new over */}
      {/* edit modal */}

      {/*edit modal over */}
      {/* upload your file modal*/}
      <Modal
        show={showUpload}
        onHide={handleUploadClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="text-dark container-fluid">
            <div className="row">
              <div className="col-11 text-center">
                <h4>Upload your file</h4>
                <span>File should be CSV</span>
              </div>
              <div className="col-1">
                <GrClose onClick={handleUploadClose} className="pointer" />
              </div>
            </div>
            <div
              className={`row text-center upload_csv ${
                selectedFile ? "" : "dragover"
              }`}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <label htmlFor="emp_upload" className="py-5">
                <img src={fileup} alt="file" />
                <p className="my-2" style={{ fontSize: "10px" }}>
                  {uploadFile ? uploadFile.name : "Drag & drop your files here"}
                </p>
              </label>
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];

                  if (file) {
                    const fileExtension = file.name
                      .split(".")
                      .pop()
                      .toLowerCase();
                    if (fileExtension === "csv") {
                      const files = e.target.files[0];

                      setSelectedFile(files);
                      setuploadFile(files);
                    } else {
                      // alert('Please drop a CSV file.');
                      toast.error("Please drop a CSV file.", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                  }
                }}
                className="d-none"
                id="emp_upload"
                accept=".csv"
              />
            </div>
            {dragdropshow ? (
              <div className="row upload_success my-2">
                <div className="container-fluid py-3">
                  <div className="row">
                    <span className="fs14">Uploaded files</span>
                  </div>
                  <div className="progresss row d-flex align-items-center _100done">
                    <div className="col-1 pe-0">
                      <img src={success_csv} alt="success" />
                    </div>
                    <div className="col-10 ">
                      <span style={{ fontSize: "12px" }}>
                        {uploadFile ? uploadFile.name : "May New Employees.CSV"}
                      </span>
                      {/* <div class="progress-bar " role="progressbar">
                        {progress}
                      </div> */}

                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width: `${progress}%`,
                        }}
                        aria-valuenow={progress}
                      >
                        <span class="sr-only puraTotal">{`${progress}%`}</span>
                      </div>
                    </div>
                    {progress === 100 && (
                      <div className="col-1 p-0">
                        <img src={success} alt="success" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row mt-3">
              <div className="col p-0">
                <button
                  className=" btn-purple  text-white"
                  onClick={() => {
                    if (uploadFile) {
                      uploademployeeFile(token, facility_Id, uploadFile);
                    }
                  }}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* upload your file end */}
      <ToastContainer />
    </div>
  );
};

export default Employee;
