import { configureStore } from "@reduxjs/toolkit"
import getFacility from "./slice/facility"
import sideReducer from "./slice/sidebarSlice"
import employee from "./slice/employee"
import dashboardBodyfacility from "./slice/dashboardBodyfacility"
import filterReducer from "./slice/filterslice"
import dashboardChart from "./slice/DashboardChart"
import managerslice from './slice/manager'
import departmentslice from './slice/department'
export const store = configureStore({
    reducer: {
        Facility: getFacility, hamburger: sideReducer,
        employee:employee,
        bodyfacilitydata:dashboardBodyfacility,
        filter:filterReducer,
        chart:dashboardChart,
        manager:managerslice,
        department:departmentslice
    }
})