import React, { useState } from "react";
import { Table } from "react-bootstrap";

import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Switch from "@mui/material/Switch";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";

import { GrClose } from "react-icons/gr";
import load from "../../assets/img/loading.gif";
import loginloader from "../../assets/img/loading.gif";
import Sort from "../../assets/img/Sort.png";
import Modal from "react-bootstrap/Modal";
import {
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField,
} from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addmanager,
  getmanager,
  getsinglemanager,
  managertateblank,
  update_manager,
} from "../../redux/action/manager";
import { getdepartment } from "../../redux/action/department";
const label = { inputProps: { "aria-label": "Switch demo" } };

const ManagerTable = ({ datatable }) => {
  // edit modal
  const [showEdit, setshowEdit] = useState(false);
  const [termination, settermination] = useState(false);
  const [select, setselect] = useState("");
  const handleEditClose = () => {
    // setdepartmentdatas([])
    settermination(false);
    setshowEdit(false);
  };
  const handleEditShow = () => setshowEdit(true);
  //
  const animatedComponents = makeAnimated();
  //
  const [rows, setrows] = useState([]);

  const dispatch = useDispatch();
  const [fillter_position, setfillter_position] = useState([]);

  const { manager } = useSelector((state) => state);
  const {
    isLoading,
    addmanagerData,
    update_managerData,
    update_loading,
    singlemanagerData,
  } = useSelector((state) => state.manager);

  const { departmentdata } = useSelector((state) => state.department);
  const { showSidebar } = useSelector((state) => state.hamburger);
  const { bodycallofdata } = useSelector((state) => state.bodyfacilitydata);
  //
  const cookie = new Cookies();
  const navigate = useNavigate();
  const token = cookie.get("token");
  const { data } = useSelector((state) => state.Facility);
  const { facility_Id } = useSelector((state) => state.filter);
  let sessionId = JSON.parse(sessionStorage.getItem("facility"));
  const [facility, setfacility] = useState("");
  const [position, setposition] = useState("");
  const [search, setsearch] = useState("");
  const [is_terminated, setis_terminated] = useState(false);
  useEffect(() => {
    if (data?.facilities.length) {
      let id = sessionId
        ? sessionId?.id
        : facility_Id
        ? facility_Id
        : data?.facilities[0]?.id;

      dispatch(
        getmanager({
          token,
          id,
          name: search,
          position,
          is_terminated,
        })
      );
    }
  }, [data, facility_Id, search, is_terminated, position]);
  const [departmentdatas, setdepartmentdatas] = useState([]);
  const [departmenDisplay, setdepartmenDisplay] = useState([]);
  
  useEffect(() => {
    if (manager?.manager) {
      setrows(manager?.manager);
    }
  }, [manager?.manager]);

  const [options, setoptions] = useState([]);
  const [id, setid] = useState("");
  useEffect(() => {
    if (departmentdata) {
      let arr = [];
      departmentdata &&
        departmentdata.forEach((it) => {
          arr.push({ value: it.id, label: it.name });
          setoptions(arr);
        });
    }
  }, [departmentdata]);

  useEffect(() => {
    setfillter_position(bodycallofdata?.positions);
  }, [bodycallofdata]);
  const [startDate, setStartDate] = useState(new Date());
  const [managerdata, setmanagerdata] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    can_text: false,
    can_email: false,
    id: "",
    facility:
      sessionId !== null || sessionId !== undefined
        ? sessionId?.id
        : facility_Id
        ? facility_Id
        : data?.facilities[0]?.id,
    position: "",
    department: [1],
  });
  const [date, setdate] = useState(new Date());
  useEffect(() => {
    setmanagerdata({
      first_name: singlemanagerData?.data?.first_name
        ? singlemanagerData.data?.first_name
        : "",
      last_name: singlemanagerData?.data?.last_name
        ? singlemanagerData.data?.last_name
        : "",
      facility: singlemanagerData?.data?.facility,
      email: singlemanagerData?.data?.email,
      full_name: singlemanagerData?.data?.full_name,
      // is_terminated: singlemanagerData?.data?.is_terminated,
      badge_id: singlemanagerData?.data?.badge_id,
      id: singlemanagerData?.data?.id,
      position: singlemanagerData?.data?.position,
      phone: singlemanagerData?.data?.phone,
      can_email: singlemanagerData?.data?.can_email,
      can_text: singlemanagerData?.data?.can_text,
    });
    settermination(singlemanagerData?.data?.is_terminated);
    const inputDate = new Date(singlemanagerData?.data?.termination_date);

    // Get the date components
    const day = inputDate.getUTCDate();
    const month = inputDate.getUTCMonth() + 1; // Months are zero-based, so add 1
    const year = inputDate.getUTCFullYear();

    // Format the date in the desired format
    const simpleDate = `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;

    const inputDateString = simpleDate;

    // Split the input date string into month, day, and year components
    const [months, days, years] = inputDateString.split("/");

    // Create a new Date object from the parsed components
    const inputDates = new Date(`${years}-${months}-${days}`);

    // Get the number of milliseconds since the Unix Epoch
    const millisecondsSinceEpoch = inputDates.getTime();

    setStartDate(millisecondsSinceEpoch);

    setdate(singlemanagerData?.data?.termination_date);
    let arr = [];
    let depart = singlemanagerData?.data?.department;
    for (let i = 0; i < singlemanagerData?.data?.department.length; i++) {
      for (let j = 0; j < options.length; j++) {
        if (singlemanagerData?.data?.department[i] == options[j].value) {
          arr.push({
            value: singlemanagerData?.data?.department[i],
            label: options[j].label,
          });
        }
      }
    }

    setdepartmenDisplay([...arr]);
    setdepartmentdatas(singlemanagerData?.data?.department);
  }, [singlemanagerData]);

  const handleChange = (e) => {
    if (e.target.name === "can_text" || e.target.name === "can_email") {
      return setmanagerdata({
        ...managerdata,
        [e.target.name]: e.target.checked,
      });
    }
    setmanagerdata({ ...managerdata, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (update_managerData?.response?.status === 400) {
      let arr = [];
      let err = update_managerData?.response?.data?.message;
      for (const i in err) {
        let msg = i + " " + err[i];
        arr.push(msg);
      }
      toast.error(arr[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (update_managerData?.status === 200 && update_managerData !== null) {
      toast.success("successfully updated ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      settermination(false);
      dispatch(
        getmanager({
          token,
          id: facility_Id,
          name: search,
          position,
          is_terminated,
        })
      );
      dispatch(managertateblank());

      setshowEdit(false);
    }
  }, [update_managerData]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
    // if (sortColumn !== column) {
    //   setSortColumn(column);
    //   setSortOrder([...rows].sort((a, b) => a[column].localeCompare(b[column])));
    // } else {
    //   setSortColumn("");
    //   setSortOrder([...rows].reverse());
    // }
  };

  const sortedRows = [...rows].sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (valueA < valueB) {
      return sortOrder === "asc" ? -1 : 1;
    } else if (valueA > valueB) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  const sortRows = () => {
    if (!sortColumn) return [...sortedRows]; // No sorting if no column is selected

    const sorted = [...sortedRows].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (typeof valueA === "string" && typeof valueB === "string") {
        // String sorting (word-wise)
        const lowercaseA = valueA.toLowerCase();
        const lowercaseB = valueB.toLowerCase();

        if (sortOrder === "asc") {
          return lowercaseA.localeCompare(lowercaseB);
        } else {
          return lowercaseB.localeCompare(lowercaseA);
        }
      } else if (valueA instanceof Date && valueB instanceof Date) {
        // Date sorting
        if (sortOrder === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      } else {
        // For other data types, return 0 to maintain the original order
        return 0;
      }
    });

    return sorted;
  };

  const sortedAndFilteredRows = sortRows();
  const location = useLocation();

  return (
    <>
      {!isLoading ? (
        <div
          className="p-3 table_main"
          style={{ backgroundColor: "#2D333A", borderRadius: "10px" }}
        >
          <Table striped bordered hover>
            <thead style={{ backgroundColor: "#14181D" }}>
              <tr>
                <th onClick={() => handleSort("first_name")}>
                  {" "}
                  <img src={Sort} alt="a" className="pointer" />
                  First Name
                </th>
                <th onClick={() => handleSort("last_name")}>
                  {" "}
                  <img src={Sort} alt="aa" className="pointer" />
                  Last Name
                </th>

                <th>Department</th>
                <th>Phone Number</th>
                {location?.state?.is_terminated ? (
                  <th onClick={() => handleSort("termination_date")}>
                    <img src={Sort} alt="aa" className="pointer" /> Termination
                    Date
                  </th>
                ) : (
                  ""
                )}
                <th onClick={() => handleSort("email")}>
                  {" "}
                  <img src={Sort} alt="aa" className="pointer" />
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredRows.map((row, i) => (
                <tr key={row.badge_id} id={row.id}>
                  <td
                    style={{ textDecoration: "underline" }}
                    className="ps-3 pointer"
                    onClick={() => {
                      handleEditShow();
                      setid(row?.id);
                      dispatch(getsinglemanager({ token, id: row?.id }));
                    }}
                  >
                    {row.first_name.length > 17
                      ? row.first_name.slice(0, 17)
                      : row.first_name}
                  </td>
                  <td>
                    {row.last_name.length > 17
                      ? row.last_name.slice(0, 17)
                      : row.last_name}
                  </td>

                  <td>
                    <div style={{ width: "120px" }}>
                      <select
                        value={select}
                        name=""
                        id=""
                        className="form-control pointer"
                        style={{
                          color: "white",
                          backgroundColor: "transparent",
                        }}
                      >
                        {row.department.map((it) => {
                          return (
                            <option
                              value=""
                              style={{
                                backgroundColor: "#4a4848",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              {it}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>{row.phone}</td>
                  {row.is_terminated ? (
                    <td>
                      {row.termination_date ? row.termination_date : "N-A"}
                    </td>
                  ) : (
                    ""
                  )}
                 <td>{row.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <img src={load} alt="loading" />
        </div>
      )}
      <Modal
        show={showEdit}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Body>
          {!singlemanagerData ? (
            <div className="text-dark container-fluid">
              <img className="gif" src={loginloader} />
            </div>
          ) : (
            <div className="text-dark container-fluid">
              <div className="row">
                <div className="col-11">
                  <h3>Edit Information</h3>
                </div>
                <div className="col-1 ">
                  <GrClose onClick={handleEditClose} className="pointer" />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <FormControl size="" className="form-control">
                    <label htmlFor="dept">Departments</label>
                  </FormControl>
                  <MultiSelect
                    className="dept_multi "
                    value={departmenDisplay}
                    options={options}
                    name=""
                    onChange={(val) => {
                      let arr = [];
                      val.forEach((it) => {
                        arr.push(it.value);
                      });

                      setdepartmentdatas(arr);
                      setdepartmenDisplay(val);
                    }}
                  />
                </div>

                <div className="col-6">
                  <FormControl size="" className="form-control">
                    <label htmlFor="email">Email</label>
                    <input
                      className="form-control input-11"
                      id="outlined-basic"
                      placeholder="email"
                      variant="outlined"
                      name="email"
                      value={managerdata.email}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <Divider className="my-4"></Divider>
              <div className="row">
                <div className="col-6">
                  <FormControl size="" className="form-control">
                    <label htmlFor="first name">First Name</label>
                    <input
                      className="form-control input-11"
                      id="outlined-basic"
                      placeholder="First Name"
                      variant="outlined"
                      name="first_name"
                      value={managerdata.first_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
                <div className="col-6">
                  <label htmlFor="last name">Last Name</label>
                  <FormControl size="" className="form-control">
                    <input
                      className="form-control input-11"
                      id="outlined-basic"
                      placeholder="Last Name"
                      variant="outlined"
                      name="last_name"
                      value={managerdata.last_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-6">
                  <p> Termination Status</p>
                  <Switch
                    {...label}
                    // value={termination}
                    checked={termination ? "checked" : ""}
                    onChange={(e) => {
                      settermination(e.target.checked);
                    }}
                  />
                </div>
                <div className="col-6">
                  {termination && (
                    <div>
                      <p>Termination Date</p>
                      <FormControl size="" className="form-control">
                        <DatePicker
                          className="date-emp"
                          name="termination_date"
                          selected={new Date()}
                          onChange={(date) => {
                            setStartDate(date);
                            setdate(date);
                          }}
                        />
                      </FormControl>
                    </div>
                  )}
                </div>


                <div className="col-6">
                  <FormControl size="" className="form-control">
                    <label htmlFor="phone">Phone</label>
                    <input
                      className="form-control input-11"
                      id="outlined-basic"
                      placeholder="Phone"
                      type="number"
                      variant="outlined"
                      name="phone"
                      value={managerdata.phone}
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-6">
                  <span className="fs10">
                    Choose an admin role from the options provided
                  </span>
                </div>
                <div className="col-6">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      name="can_text"
                      checked={managerdata.can_text ? "checked" : ""}
                      onChange={handleChange}
                    />

                    <label class="form-check-label fs10" for="inlineCheckbox1">
                      Send SMS/Text
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                      name="can_email"
                      checked={managerdata.can_email ? "checked" : ""}
                      onChange={handleChange}
                    />
                    <label class="form-check-label fs10" for="inlineCheckbox2">
                      Send email
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <button
                    onClick={() => {
                      dispatch(
                        update_manager({
                          token,
                          id,
                          managerdata,
                          departmentdatas,
                          date,
                          termination,
                        })
                      );
                    }}
                    className={`btn-purple  text-white ${
                      isLoading ? `p0` : ""
                    }`}
                  >
                    {update_loading ? (
                      <img className="gif" src={loginloader} />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ManagerTable;
